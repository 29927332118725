import React from "react"
import "./styles.css"

export const renderVideo = (src: string) => {
  return <div className="ContentVideoWrapper">
    <iframe
      src={`https://drive.google.com/file/d/${src.split("/")[5]}/preview`}
    />
  </div>
}

export const checkHtmlString = (value: string): boolean => value.replace(/<[^>]*>?/gm, "").trim().length > 0