import React, { useEffect, useState } from "react"

import { useHistory } from "react-router-dom"

import Menu from "Components/Menu"
import Footer from "Components/Footer"
import FadeInSection from "Components/FadeInSection"

import requestApi, { getImgUrl } from "api/request"
import { renderHtml } from "../../utils/parseHtml"
import { getLocaleFromLs } from "../../utils/localStorage"
import { LocaleKeys } from "../Admin/New/consts"
import { NewsList, NewsProps, NewsType } from "./types"
import { languageConfig, mobileVideoMap, videoMap } from "./helpers"

const News: React.FC<NewsProps> = ({ type }) => {
  const [newsList, setNewsList] = useState<{
    data: NewsList[]
    pending: boolean
    limit: number
    offset: number
    totalCount: number
  }>({
    data: [],
    pending: false,
    limit: 9,
    offset: 0,
    totalCount: 0
  })

  useEffect(() => {
    requestApi({
      url: `/news/list`,
      withToken: false,
      method: `POST`,
      requestBody: {
        limit: newsList.limit,
        offset: newsList.offset,
        type
      }
    }).then(({ data, headers }: { data: NewsList[]; headers: any }) => {
      setNewsList({ ...newsList, data: data, totalCount: headers.count || 9 })
    })
  }, [newsList.limit, type])

  const contentLength = newsList.totalCount > newsList.data.length

  const [currentLanguage, changeCurrentLanguage] = useState<LocaleKeys>(getLocaleFromLs)

  const history = useHistory()

  const imgUrl: string = getImgUrl()

  const isSmallMobile = window.innerWidth < 375
  const isDesktop = window.innerWidth > 1024

  return (
    <div className="NewsWrapper">
      <div className="MainBlock">
        <div className="MainBlockImg">
          <video playsInline autoPlay muted loop id={type} src={isDesktop ? videoMap[type] : mobileVideoMap[type]}/>
        </div>
        <Menu
          currentLanguage={currentLanguage}
          changeLanguage={changeCurrentLanguage}
        />
        <div className="NewsBlockTitle">
          <div className="MainBlockTitleUpper">{languageConfig[type][currentLanguage].title}</div>
          <div className="MainBlockTitleDescription">
            {languageConfig[type][currentLanguage].titleDescription.first} <br />
            {languageConfig[type][currentLanguage].titleDescription.second}
          </div>
        </div>
      </div>
      {type === NewsType.charity &&
        <div className="WhiteBlock NewsCharityBlock">
          <div className="RightBlock">
            <FadeInSection>
              <div className="WhiteBlockTitle NewsCharityBlockTitle">
                {isSmallMobile && currentLanguage == `ru`
                  ? languageConfig[type][currentLanguage].subTitleBox.mobile_title
                  : languageConfig[type][currentLanguage].subTitleBox.title}
              </div>
            </FadeInSection>
            <div className="FlexRow WhiteBlockRow">
              <FadeInSection>
                <div className="WhiteBlockItem FlexColumn">
                  <div className="WhiteBlockTextLabel">
                    {languageConfig[type][currentLanguage].subTitleBox.block_one.label}
                  </div>
                  <div className="WhiteBlockText NewsCharityBlockText">
                    {languageConfig[type][currentLanguage].subTitleBox.block_one.text}
                  </div>
                </div>
              </FadeInSection>
              <FadeInSection>
                <div className="WhiteBlockItem FlexColumn">
                  <div className="WhiteBlockTextLabel">
                    {languageConfig[type][currentLanguage].subTitleBox.block_two.label}
                  </div>
                  <div className="WhiteBlockText NewsCharityBlockText">
                    {languageConfig[type][currentLanguage].subTitleBox.block_two.text}
                  </div>
                </div>
              </FadeInSection>
            </div>
          </div>
        </div>
      }
      <div className="NewsList">
        {newsList.data.map((item, key) => (
          <FadeInSection key={`news_item_${item.id}`}>
            <div
              className="NewsListItem"
              onClick={() => history.push(`/news/${item.id}`)}
            >
              <div className="NewsListItemImgWrapper">
                <div
                  className="NewsListItemImg"
                  style={{
                    backgroundImage: `url(${imgUrl}/${item.preview})`
                  }}
                />
              </div>
              <div className="NewsListItemDate">{item.date}</div>
              <div className="NewsListItemTitle">
                {renderHtml(currentLanguage === `en`
                  ? item.title?.en
                  : item.title?.ru)}
              </div>
            </div>
          </FadeInSection>
        ))}
      </div>
      {contentLength && (
        <div className="GridCenter">
          <div
            onClick={() =>
              setNewsList({ ...newsList, limit: newsList.limit + 9 })
            }
            className="MainBlockMoreButton Button"
          >
            {currentLanguage === `en` ? `Show more` : `Показать еще`}
          </div>
        </div>
      )}
      <Footer currentLanguage={currentLanguage} />
    </div>
  )
}

export default News
