import React from "react"
import { ArrowProps } from "./types"
import { ArrowDirections } from "./consts"
import { ReactComponent as LeftArrow } from "./icons/arrow_left.svg"
import { ReactComponent as RightArrow } from "./icons/arrow_right.svg"

export const Arrow: React.FC<ArrowProps> = ({ direction, ...props }) => {
  if (direction === ArrowDirections.left) {
    return <LeftArrow {...props} />
  } else {
    return <RightArrow {...props} />
  }
}