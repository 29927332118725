import React from "react"
import { SingleLanguageProps } from "./types"
import './styles.css'

export const SingleLanguage: React.FC<SingleLanguageProps> = ({ setLocaleFn, activeLocale, localeName }) => {
  return <div
    className={`AdminNewLanguage ${activeLocale === localeName ? `AdminNewLanguageActive` : ``}`}
    onClick={() => setLocaleFn(localeName)}
  >
    {localeName.toUpperCase()}
  </div>
}