import React from "react"
import { UploadImageProps } from "./types"
import './styles.css'

export const UploadImage: React.FC<UploadImageProps> = ({
                                                          setStateFn,
                                                          imageName,
                                                          labelName,
                                                          imageSizeLabel,
                                                          multiple
                                                        }) => {
  return <div className="AdminNewFirstBlockImg">
    <label htmlFor={imageName} className="Button UploadButton">
      {labelName}
    </label>
    <div className="UploadButtonDescription"> ~ {imageSizeLabel}px</div>
    <input
      id={imageName}
      className="AdminNewFirstBlockImgInput"
      type="file"
      accept="image/jpeg,image/png,image/jpg"
      name={imageName}
      onChange={(changeEvent) => {
        const imagesPromises: any[] = []
        if (!!changeEvent.target.files) {
          const files = changeEvent.target.files
          for (let i = 0; i < files.length; i++) {
            const reader = new FileReader()
            imagesPromises.push(new Promise(res => {
              reader.onload = ev => {
                res(ev.target ? ev.target.result : null)
              }
              reader.readAsDataURL(files[i])
            }))
          }
          Promise.all(imagesPromises).then(data => setStateFn(files, data)).catch(err => alert(`Изображения были загружены с ошибкой ${err}`))
        }
      }}
      multiple={multiple}
    />
  </div>
}