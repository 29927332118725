import React from "react"
import {Carousel as AntdCarousel} from "antd"
import {CarouselProps} from "./types"
import {Arrow} from "./Arrow"

import "antd/dist/antd.css"
import "./styles.css"
import {ArrowDirections} from "./consts"

const Carousel: React.FC<CarouselProps> = (props) => {
    return <AntdCarousel autoplay arrows nextArrow={<Arrow direction={ArrowDirections.right}/>}
                         prevArrow={<Arrow direction={ArrowDirections.left}/>} {...props} />
}

export default Carousel

