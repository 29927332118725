import React, { useEffect, useMemo, useState } from "react"

import { useHistory, useParams } from "react-router-dom"

import Menu from "Components/Menu"
import Footer from "Components/Footer"
import requestApi, { getImgUrl } from "api/request"
import { CurrentNewData } from "./types"
import { ContentTypes, LocaleKeys } from "../../Admin/New/consts"
import { renderHtml } from "../../../utils/parseHtml"
import { getLocaleFromLs } from "../../../utils/localStorage"
import { CarouselWithImages } from "../../Admin/New/CaoruselWithImages"
import { renderVideo } from "../../Admin/New/helpers"
import produce from "../../../utils/immer"
import { NewsType } from "../types"


const CurrentNew = () => {
  const history = useHistory()
  const { id }: { id: string } = useParams()
  const imgUrl: string = useMemo(() => getImgUrl(), [])

  const [newData, setNewData] = useState<CurrentNewData>({
    id: id,
    title: {
      ru: ``,
      en: ``
    },
    label: {
      ru: ``,
      en: ``
    },
    blocks: [],
    date: ``,
    image: ``,
    preview: ``,
    visible: false,
    publish: false,
    another: [],
    type: NewsType.news
  })

  useEffect(() => {
    requestApi({
      url: `/news/${id}`,
      withToken: false,
      method: `GET`
    })
      .then(({ data }: { data: CurrentNewData }) => setNewData(produce(data, draft => {
          draft.type = data.type || NewsType.news
          draft.blocks = data.blocks.map(block => {
              if (!block?.contentType) {
                  block.contentType = ContentTypes.text
              }
              return block
          })
      })))
      .catch((error) => {
        if (error && error.response && error.response.status) {
          localStorage.setItem(`error`, `404`)
          history.push(`/404`)
        } else {
          history.push(`/news`)
        }
      })
  }, [id])

  const [currentLanguage, changeCurrentLanguage] = useState<LocaleKeys>(getLocaleFromLs())
  const isMobile = window.innerWidth < 1024

  return (
    <div>
      <div className="MainBlock">
        <div className="AdminNewsFirstBlock">
          {!!newData.preview && !!newData.image && (
            <div>
              {isMobile ? (
                <div
                  className="NewsListCurrentImgMobile"
                  style={{
                    backgroundImage: `url(${imgUrl}/${newData.preview})`
                  }}
                />
              ) : (
                <img
                  className="NewsListCurrentImg"
                  src={`${imgUrl}/${newData.image}`}
                />
              )}
            </div>
          )}
        </div>
        <Menu
          currentLanguage={currentLanguage}
          changeLanguage={changeCurrentLanguage}
        />
        <div className="CurrentNweOverlay" />
        <div className="CurrentNewsTitle">
          {renderHtml(newData.title[currentLanguage])}
        </div>
        <div className="CurrentNewsDate">{newData.date}</div>
      </div>
      <div className="CurrentNewsBody">
        <div className="CurrentNewsBodyTitle">
          {renderHtml(newData.label[currentLanguage])}
        </div>
        <div className="CurrentNewsBodyText">
          {newData.blocks.map((item) => {
            if (item.contentType === ContentTypes.text) {
              return <div className="CurrentNewsBodyTextBlock"
                          key={`new_block_${item.ru ? item.ru.length : 1}`}>
                {renderHtml(item[currentLanguage])}
              </div>
            }
            if (item.contentType === ContentTypes.carousel) {
              return <div>
                <CarouselWithImages images={item.images} url={imgUrl} />
              </div>
            }
            if (item.contentType === ContentTypes.video) {
              return <div>
                {renderVideo(item[currentLanguage])}
              </div>
            }
          })}
        </div>
      </div>
      <div className="NewsList">
        {newData.another.map(
          (item, key) =>
            (key === 0 || (key > 0 && window.innerWidth > 1024)) && (
              <div
                className="NewsListItem"
                onClick={() => history.push(`/news/${item.id}`)}
              >
                <div className="NewsListItemImgWrapper">
                  <div
                    className="NewsListItemImg"
                    style={{
                      backgroundImage: `url(${imgUrl}/${item.preview})`
                    }}
                  />
                </div>
                <div className="NewsListItemDate">{item.date}</div>
                <div className="NewsListItemTitle">
                  {renderHtml(item.title[currentLanguage])}
                </div>
              </div>
            )
        )}
      </div>
      <Footer currentLanguage={currentLanguage} />
    </div>
  )
}

export default CurrentNew
