import React from "react"
import { ReactComponent as FacebookIcon } from "../Footer/facebook.svg"
import { ReactComponent as InstagramIcon } from "../Footer/instagram.svg"
import { ReactComponent as WhatsUpIcon } from "../Footer/whatsapp.svg"
import { ReactComponent as TelegaIcon } from "../Footer/telegram.svg"

type MediaType = {
  active?: `instagram` | `telegram` | `whatsup` | `facebook`
  switchActive?: (
    value: `instagram` | `telegram` | `whatsup` | `facebook`
  ) => void
  links?: {
    facebook: string
    instagram: string
    whatsup: string
    telegram: string
  }
}

const Media = ({ active, switchActive, links }: MediaType) => {
  const withLinks = !!links
  return (
    <div className="FlexRow MediaWrapper">
      {/*<div*/}
      {/*  className={`FooterFacebookWrapper ${*/}
      {/*    active && active === `facebook` ? `FacebookActive` : ``*/}
      {/*  }`}*/}
      {/*  onClick={() => {*/}
      {/*    if (!!switchActive) switchActive(`facebook`)*/}
      {/*  }}*/}
      {/*>*/}
      {/*  {withLinks ? (*/}
      {/*    <a href={links.facebook}>*/}
      {/*      <FacebookIcon />*/}
      {/*    </a>*/}
      {/*  ) : (*/}
      {/*    <FacebookIcon />*/}
      {/*  )}*/}
      {/*</div>*/}
      <div
        className={`FooterInstagramWrapper ${
          active && active === `instagram` ? `InstagramActive` : ``
        }`}
        onClick={() => {
          if (!!switchActive) switchActive(`instagram`)
        }}
      >
        {withLinks ? (
          <a href={links?.instagram}>
            <InstagramIcon />
          </a>
        ) : (
          <InstagramIcon />
        )}
      </div>
      <div
        className={`FooterWhatsUpWrapper ${
          active && active === `whatsup` ? `WhatsUpActive` : ``
        }`}
        onClick={() => {
          if (!!switchActive) switchActive(`whatsup`)
        }}
      >
        {withLinks ? (
          <a href={links?.whatsup}>
            <WhatsUpIcon />
          </a>
        ) : (
          <WhatsUpIcon />
        )}
      </div>
      {/*<div*/}
      {/*  className={`FooterTelegaWrapper ${*/}
      {/*    active && active === `telegram` ? `TelegaActive` : ``*/}
      {/*  }`}*/}
      {/*  onClick={() => {*/}
      {/*    if (!!switchActive) switchActive(`telegram`)*/}
      {/*  }}*/}
      {/*>*/}
      {/*  {withLinks ? (*/}
      {/*    <a href={links?.telegram}>*/}
      {/*      <TelegaIcon />*/}
      {/*    </a>*/}
      {/*  ) : (*/}
      {/*    <TelegaIcon />*/}
      {/*  )}*/}
      {/*</div>*/}
    </div>
  )
}

export default Media
