import { NewsType } from "../../News/types"

export enum LocaleKeys {
  en = "en",
  ru = "ru",
}

export enum ContentTypes {
  text = "text",
  carousel = "carousel",
  video = "video",
}

export enum SaveStatusTypes {
  save = "save",
  publish = "publish",
  error = "error",
  validate = "validate",
}

export const TextMocks = {
  headerTitleRU: "Заголовок статьи RU",
  headerTitleEN: "Заголовок статьи EN",
  bodyTitleRu: "Заголовок новости RU",
  bodyTitleEn: "Заголовок новости EN",
  bodyBlockNewsRu: "Блок новостей RU",
  bodyBlockNewsEn: "Блок новостей EN",
  back: "Назад",
  text: "Текстовый блок",
  carousel: "Карусель",
  video: "Видео",
  loadButton: "Загрузить",
  headerModalTitle: "Шапка статьи",
  redirectToMain: "На главную",
  imagePreview: "Изображение превью новости",
  imageHeader: "Изображение для шапки статьи",
  error: "Произошла ошибка",
  errorFields: "Заполните все поля",
  errorCreation: "при создании",
  successCreation: "Новость успешно создана",
  successCreationPublish: "Новость успешно создана и опубликована",
  saveAndPublish: "Сохранить и опубликовать",
  save: "Сохранить",
  loadHeaderImage: "Загрузить обложку",
  headerImageDimensions: "2048x1152",
  carouselImageDimensions: "870x480",
  loadCarouselImage: "Загрузить изображения в карусель",
  close: "Закрыть",
  [NewsType.news]: "Раздел новости",
  [NewsType.charity]: "Раздел ответственность",
}

export const newBlockItem = {
  [LocaleKeys.ru]: ``,
  [LocaleKeys.en]: ``,
}

export const newBlockItemTitle = {
  [LocaleKeys.ru]: TextMocks.bodyTitleRu,
  [LocaleKeys.en]: TextMocks.bodyTitleEn,
}

export const newImageItem = {
  base: null,
  file: null,
}

export const newBlock = {
  ...newBlockItem,
  imagesBase: null,
  imagesFile: null,
}
