import React, { useEffect, useState } from "react"

import { ReactComponent as EditIcon } from "./edit.svg"
import { ReactComponent as DoneIcon } from "./done.svg"
import { ReactComponent as CancelIcon } from "./cancel.svg"

import requestApi from "api/request"
import InputMask from "react-input-mask"

import Media from "Components/Media"
import { ContactsType } from "../index"

const AdminContacts = ({
  logOut,
  initial,
}: {
  logOut: () => void
  initial: ContactsType
}) => {
  const [initialValues, setInitialValues] = useState<ContactsType>(initial)
  const [values, setValues] = useState<ContactsType>(initial)
  const [pending, togglePending] = useState<boolean>(false)
  useEffect(() => {
    togglePending(true)
    requestApi({
      url: `/contacts`,
      method: `GET`,
      withToken: false,
      logOut,
    }).then((response: { data: ContactsType }) => {
      setInitialValues(response.data)
      setValues(response.data)
      togglePending(false)
    })
  }, [])
  const [activeField, toggleActiveField] = useState<{
    address: { ru: boolean; en: boolean }
    mail: boolean
    phone: boolean
    addPhone: boolean
    media: {
      value: boolean
      name: `instagram` | `telegram` | `whatsup` | `facebook`
    }
  }>({
    address: { ru: false, en: false },
    mail: false,
    phone: false,
    addPhone: false,
    media: {
      value: false,
      name: `facebook`,
    },
  })

  const saveContact = (newValue: ContactsType) => {
    togglePending(true)
    return requestApi({
      url: `/contacts`,
      method: `PUT`,
      withToken: true,
      logOut,
      requestBody: newValue,
    }).then(() => togglePending(false))
  }

  return (
    <div className="AdminFooterBody">
      <div className="AdminFooterBodyInputs">
        <div className="FlexRow AdminFooterItem">
          <div className="AdminFooterItemLabel">Адрес:</div>
          {!activeField.address.ru ? (
            <div
              onClick={() =>
                toggleActiveField({
                  ...activeField,
                  address: { ...activeField.address, ru: true },
                })
              }
              className="AdminFooterItemValue FlexRow"
            >
              <div>{values.address.ru}</div>
              <EditIcon />
            </div>
          ) : (
            <div className="FlexRow">
              <input
                disabled={pending}
                className="AdminFooterItemInput"
                value={values.address.ru}
                onChange={({ target: { value } }) => {
                  setValues({
                    ...values,
                    address: { ...values.address, ru: value },
                  })
                }}
              />
              <DoneIcon
                onClick={() => {
                  saveContact({ ...values, address: values.address }).then(
                    () => {
                      setInitialValues({ ...values, address: values.address })
                      toggleActiveField({
                        ...activeField,
                        address: { ...activeField.address, ru: false },
                      })
                    }
                  )
                }}
                className="DoneIcon"
              />
              <CancelIcon
                onClick={() => {
                  setValues({ ...values, address: initialValues.address })
                  toggleActiveField({
                    ...activeField,
                    address: { ...activeField.address, ru: false },
                  })
                }}
                className="CancelIcon"
              />
            </div>
          )}
        </div>
        <div className="FlexRow AdminFooterItem">
          <div className="AdminFooterItemLabel">Email:</div>
          {!activeField.mail ? (
            <div
              onClick={() => toggleActiveField({ ...activeField, mail: true })}
              className="AdminFooterItemValue FlexRow"
            >
              <div>{values.mail}</div>
              <EditIcon />
            </div>
          ) : (
            <div className="FlexRow">
              <input
                disabled={pending}
                className="AdminFooterItemInput"
                value={values.mail}
                onChange={({ target: { value } }) => {
                  setValues({ ...values, mail: value })
                }}
              />
              <DoneIcon
                onClick={() => {
                  saveContact({ ...values, mail: values.mail }).then(() => {
                    setInitialValues({ ...values, mail: values.mail })
                    toggleActiveField({ ...activeField, mail: false })
                  })
                }}
                className="DoneIcon"
              />
              <CancelIcon
                onClick={() => {
                  setValues({ ...values, mail: initialValues.mail })
                  toggleActiveField({ ...activeField, mail: false })
                }}
                className="CancelIcon"
              />
            </div>
          )}
        </div>
        <div className="FlexRow AdminFooterItem">
          <div className="AdminFooterItemLabel">Address:</div>
          {!activeField.address.en ? (
            <div
              onClick={() =>
                toggleActiveField({
                  ...activeField,
                  address: { ...activeField.address, en: true },
                })
              }
              className="AdminFooterItemValue FlexRow"
            >
              <div>{values.address.en}</div>
              <EditIcon />
            </div>
          ) : (
            <div className="FlexRow">
              <input
                disabled={pending}
                className="AdminFooterItemInput"
                value={values.address.en}
                onChange={({ target: { value } }) => {
                  setValues({
                    ...values,
                    address: { ...values.address, en: value },
                  })
                }}
              />
              <DoneIcon
                onClick={() => {
                  saveContact({ ...values, address: values.address }).then(
                    () => {
                      setInitialValues({ ...values, address: values.address })
                      toggleActiveField({
                        ...activeField,
                        address: { ...activeField.address, en: false },
                      })
                    }
                  )
                }}
                className="DoneIcon"
              />
              <CancelIcon
                onClick={() => {
                  setValues({ ...values, address: initialValues.address })
                  toggleActiveField({
                    ...activeField,
                    address: { ...activeField.address, en: false },
                  })
                }}
                className="CancelIcon"
              />
            </div>
          )}
        </div>
        <div className="AdminFooterItem" />
        <div className="FlexRow AdminFooterItem">
          <div className="AdminFooterItemLabel">Телефон:</div>
          {!activeField.phone ? (
            <div
              onClick={() =>
                toggleActiveField({
                  ...activeField,
                  phone: true,
                })
              }
              className="AdminFooterItemValue FlexRow"
            >
              <div>{values.phoneOne}</div>
              <EditIcon />
            </div>
          ) : (
            <div className="FlexRow">
              <InputMask
                disabled={pending}
                className="AdminFooterItemInput"
                mask="9 999 999 99 99"
                value={values.phoneOne}
                onChange={({ target: { value } }) =>
                  setValues({ ...values, phoneOne: value })
                }
              />
              {!!values.phoneOne && values.phoneOne.slice(-1) !== `_` && (
                <DoneIcon
                  onClick={() => {
                    saveContact({ ...values, phoneOne: values.phoneOne }).then(
                      () => {
                        setInitialValues({
                          ...initialValues,
                          phoneOne: values.phoneOne,
                        })
                        toggleActiveField({
                          ...activeField,
                          phone: false,
                        })
                      }
                    )
                  }}
                  className="DoneIcon"
                />
              )}
              <CancelIcon
                onClick={() => {
                  setValues({ ...values, phoneOne: initialValues.phoneOne })
                  toggleActiveField({
                    ...activeField,
                    phone: false,
                  })
                }}
                className="CancelIcon"
              />
            </div>
          )}
        </div>
        <div className="FlexRow AdminFooterItem">
          <div className="AdminFooterItemLabel">Доп. телефон:</div>
          {!activeField.addPhone ? (
            <div
              onClick={() =>
                toggleActiveField({
                  ...activeField,
                  addPhone: true,
                })
              }
              className="AdminFooterItemValue FlexRow"
            >
              <div>{values.phoneTwo}</div>
              <EditIcon />
            </div>
          ) : (
            <div className="FlexRow">
              <InputMask
                disabled={pending}
                className="AdminFooterItemInput"
                mask="+7 (999) 999-99-99"
                value={values.phoneTwo}
                onChange={({ target: { value } }) =>
                  setValues({ ...values, phoneTwo: value })
                }
              />
              {!!values.phoneTwo && values.phoneTwo.slice(-1) !== `_` && (
                <DoneIcon
                  onClick={() => {
                    saveContact({ ...values, phoneTwo: values.phoneTwo }).then(
                      () => {
                        setInitialValues({
                          ...initialValues,
                          phoneTwo: values.phoneTwo,
                        })
                        toggleActiveField({
                          ...activeField,
                          addPhone: false,
                        })
                      }
                    )
                  }}
                  className="DoneIcon"
                />
              )}
              <CancelIcon
                onClick={() => {
                  setInitialValues({
                    ...initialValues,
                    phoneTwo: values.phoneTwo,
                  })
                  toggleActiveField({
                    ...activeField,
                    addPhone: false,
                  })
                }}
                className="CancelIcon"
              />
            </div>
          )}
        </div>
      </div>
      <div className="AdminFooterBodyMedia">
        <div className="AdminFooterItemLabel AdminFooterMediaLabel">Media</div>
        <div className="FlexRow">
          <div className="AdminFooterMediaItem">
            <Media
              active={activeField.media.name}
              switchActive={(
                value: `instagram` | `telegram` | `whatsup` | `facebook`
              ) => {
                toggleActiveField({
                  ...activeField,
                  media: { name: value, value: false },
                })
              }}
            />
          </div>
          <div className="AdminFooterMediaItemValue">
            {!activeField.media.value ? (
              <div
                onClick={() =>
                  toggleActiveField({
                    ...activeField,
                    media: { ...activeField.media, value: true },
                  })
                }
                className="AdminFooterItemValue FlexRow"
              >
                <div>
                  {values.links[activeField.media.name] || `Пустая ссылка`}
                </div>
                <EditIcon />
              </div>
            ) : (
              <div className="FlexRow">
                <input
                  disabled={pending}
                  className="AdminFooterItemInput"
                  value={values.links[activeField.media.name]}
                  onChange={({ target: { value } }) => {
                    setValues({
                      ...values,
                      links: {
                        ...values.links,
                        [activeField.media.name]: value,
                      },
                    })
                  }}
                />
                <DoneIcon
                  onClick={() => {
                    saveContact({
                      ...values,
                      links: {
                        ...initialValues.links,
                        [activeField.media.name]:
                          values.links[activeField.media.name],
                      },
                    }).then(() => {
                      setInitialValues({
                        ...initialValues,
                        links: {
                          ...initialValues.links,
                          [activeField.media.name]:
                            values.links[activeField.media.name],
                        },
                      })
                      toggleActiveField({
                        ...activeField,
                        media: { ...activeField.media, value: false },
                      })
                    })
                  }}
                  className="DoneIcon"
                />
                <CancelIcon
                  onClick={() => {
                    setValues({
                      ...values,
                      links: {
                        ...values.links,
                        [activeField.media.name]:
                          values.links[activeField.media.name],
                      },
                    })
                    toggleActiveField({
                      ...activeField,
                      media: { ...activeField.media, value: false },
                    })
                  }}
                  className="CancelIcon"
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default AdminContacts
