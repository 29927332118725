import React, { useState } from "react"
import { Route } from "react-router-dom"
import Login from "./Login"
import MainAdmin from "./Main"
import AdminNew from "./New"

const Admin = () => {
  const [isAuth, setAuth] = useState<boolean>(!!localStorage.getItem(`token`))
  const logOut = () => {
    localStorage.clear()
    setAuth(false)
  }
  return (
    <div>
      {!isAuth ? (
        <Route exact path="/admin">
          <Login setAuth={setAuth} />
        </Route>
      ) : (
        <div>
          <Route exact path="/admin">
            <MainAdmin logOut={logOut} />
          </Route>
          <Route exact path="/admin/:id">
            <AdminNew logOut={logOut} />
          </Route>
        </div>
      )}
    </div>
  )
}

export default Admin
