import React, { useCallback, useEffect, useMemo, useState } from "react"
import { useHistory, useParams } from "react-router-dom"
import { Select } from "Components/Select"
import { Input } from "Components/Input"
import { ReactComponent as PlusIcon } from "../Main/plus.svg"
import { ReactComponent as CloseIcon } from "./icons/close.svg"
import { ReactComponent as EditIcon } from "./icons/edit.svg"
import { ReactComponent as EditTextIcon } from "./icons/edit_text.svg"
import { ReactComponent as PendingIcon } from "./icons/pending.svg"
import { ReactComponent as SuccessIcon } from "./icons/success.svg"
import { ReactComponent as ErrorIcon } from "./icons/error.svg"
import requestApi, { getImgUrl } from "api/request"
import EditorComponent from "Components/Editor"
import { checkHtmlString, renderVideo } from "./helpers"
import { renderHtml } from "utils/parseHtml"
import produce from "utils/immer"
import {
  BodyState,
  HeaderState,
  PreviewImage,
  SaveStatusState,
  StatusState,
} from "./types"
import { CurrentNewData } from "../../News/Current/types"
import {
  ContentTypes,
  LocaleKeys,
  newBlock,
  newBlockItem,
  newBlockItemTitle,
  newImageItem,
  SaveStatusTypes,
  TextMocks,
} from "./consts"
import { DateTime } from "utils/dates"
import { NewsType } from "../../News/types"
import { UploadImage } from "./UploadImage"
import { ButtonsGroup } from "./ButtonsGroup"
import { CarouselWithImages } from "./CaoruselWithImages"
import { SingleLanguage } from "./SingleLanguage"

const AdminNew = ({ logOut }: { logOut: () => void }) => {
  const dayDesc = useMemo(() => DateTime.now().toFormat("MM.dd.yyyy"), [])
  const { id }: { id: string } = useParams()
  const history = useHistory()
  const isNew = id === `new`
  const [saveStatus, setSaveStatus] = useState<SaveStatusState>({
    pending: false,
    success: undefined,
  })
  const [locale, setLocale] = useState(LocaleKeys.ru)
  const [header, setHeader] = useState<HeaderState>({
    title: newBlockItem,
    image: newImageItem,
    active: true,
  })
  const [headerContentInitial, setHeaderContentInitial] = useState<HeaderState>(
    {
      title: newBlockItem,
      image: newImageItem,
      active: true,
    }
  )
  const [body, setBody] = useState<BodyState>({
    title: {
      active: false,
      value: newBlockItemTitle,
      initial: newBlockItemTitle,
    },
    blocks: [],
  })
  const [bodyContentInitial, setBodyContentInitial] = useState<BodyState>({
    title: {
      active: false,
      value: newBlockItemTitle,
      initial: newBlockItemTitle,
    },
    blocks: [],
  })
  const [bodyContentType, setBodyContentType] = useState(ContentTypes.text)
  const [newsType, setNewsType] = useState(NewsType.news)
  const [preview, setPreview] = useState<PreviewImage>(newImageItem)
  const imgUrl = useMemo(() => getImgUrl(), [])
  const [status, setStatus] = useState<StatusState>({
    visible: true,
    publish: false,
  })

  useEffect(() => {
    if (!isNew) {
      requestApi({
        url: `/news/all/${id}`,
        withToken: true,
        method: `GET`,
        logOut,
      }).then(({ data }: { data: CurrentNewData }) => {
        setHeader({
          ...header,
          title: data.title,
          image: { base: null, file: data.image },
        })
        setHeaderContentInitial({
          ...header,
          title: data.title,
          image: { base: null, file: data.image },
        })
        setBody({
          ...body,
          title: { value: data.label, initial: data.label, active: false },
          blocks: data.blocks.map((item) => ({
            value: { ...item, imagesFile: item.images, imagesBase: null },
            initial: { ...item, imagesFile: item.images, imagesBase: null },
            active: false,
            contentType: item.contentType || ContentTypes.text,
          })),
        })
        setBodyContentInitial({
          ...body,
          title: { value: data.label, initial: data.label, active: false },
          blocks: data.blocks.map((item) => ({
            value: { ...item, imagesFile: item.images, imagesBase: null },
            initial: { ...item, imagesFile: item.images, imagesBase: null },
            active: false,
            contentType: item.contentType || ContentTypes.text,
          })),
        })
        setPreview({ base: null, file: data.preview })
        setStatus({ visible: data.visible, publish: data.publish })
        setNewsType(data.type || NewsType.news)
      })
    }
  }, [])

  const save = (withPublish: boolean, withVisible: boolean) => {
    setSaveStatus({ pending: true, success: undefined })
    const requestData = {
      title: header.title,
      blocks: body.blocks.map((item) => {
        return {
          ...item.value,
          contentType: item.contentType,
          images: item.value.imagesBase || item.value.imagesFile,
        }
      }),
      label: body.title.value,
      img: header.image.base || header.image.file,
      preview: preview.base || preview.file,
      visible: withVisible,
      publish: withPublish,
      type: newsType,
    }

    requestApi({
      url: `/news${isNew ? `` : `/${id}`}`,
      withToken: true,
      method: isNew ? `POST` : `PUT`,
      requestBody: requestData,
      logOut,
    })
      .then(() => {
        setSaveStatus({
          pending: false,
          success: withPublish ? SaveStatusTypes.save : SaveStatusTypes.publish,
        })
        history.push(`/admin`)
      })
      .catch(() => {
        setSaveStatus({ pending: false, success: SaveStatusTypes.error })
      })
  }

  const disabledSave = useMemo(
    () =>
      (!header.image.base && !header.image.file) ||
      !checkHtmlString(header.title.ru) ||
      !checkHtmlString(header.title.en) ||
      !checkHtmlString(body.title.value.en) ||
      !checkHtmlString(body.title.value.ru) ||
      !body.blocks.length ||
      (!preview.file && !preview.base),
    [header, body, preview]
  )

  const setHeaderTitle = useCallback(
    (value: string, localeKey: LocaleKeys) => {
      setHeader(
        produce(header, (draft) => {
          draft.title[localeKey] = value
        })
      )
    },
    [header]
  )

  const setBodyTitle = useCallback(
    (value: string, localeKey: LocaleKeys) => {
      setBody(
        produce(body, (draft) => {
          draft.title.value[localeKey] = value
        })
      )
    },
    [body]
  )

  const setBodyBlockValue = useCallback(
    (value: string, localeKey: LocaleKeys, idx: number) => {
      setBody(
        produce(body, (draft) => {
          draft.blocks[idx].value[localeKey] = value
        })
      )
    },
    [body.blocks]
  )

  return (
    <div>
      <div
        className="AdminNewFirstBlockBack Button"
        onClick={() => history.push(`/admin`)}
      >
        {TextMocks.back}
      </div>
      <div className="AdminNewSwitchLanguage FlexRow">
        <SingleLanguage
          setLocaleFn={setLocale}
          activeLocale={locale}
          localeName={LocaleKeys.ru}
        />
        <SingleLanguage
          setLocaleFn={setLocale}
          activeLocale={locale}
          localeName={LocaleKeys.en}
        />
      </div>
      {header.active ? (
        <div>
          <div className="AdminNewFirstBlock">
            <div>
              <div className="AdminNewFirstBlockTitle">
                <div className="FlexRow">
                  <div className="AdminNewFirstBlockLabel">
                    {TextMocks.headerModalTitle}
                  </div>
                  <div
                    className="AdminNewFirstBlockClose"
                    onClick={() =>
                      setHeader(
                        produce(header, (draft) => {
                          draft.active = false
                        })
                      )
                    }
                  >
                    <CloseIcon />
                  </div>
                </div>
                <EditorComponent
                  initial={headerContentInitial.title.ru}
                  setFormData={(value) => setHeaderTitle(value, LocaleKeys.ru)}
                  placeholder={TextMocks.headerTitleRU}
                  stripPastedStyles
                />
                <EditorComponent
                  initial={headerContentInitial.title.en}
                  setFormData={(value) => setHeaderTitle(value, LocaleKeys.en)}
                  placeholder={TextMocks.headerTitleEN}
                  stripPastedStyles
                />
              </div>
              <UploadImage
                setStateFn={(files, baseFiles) => {
                  setHeader(
                    produce(header, (draft) => {
                      draft.image.file = files[0]
                      draft.image.base = baseFiles[0]
                    })
                  )
                }}
                imageName={"header-img"}
                labelName={TextMocks.loadHeaderImage}
                imageSizeLabel={TextMocks.headerImageDimensions}
              />
            </div>
          </div>
          <div className="AdminNewFirstBlockOverlay" />
        </div>
      ) : (
        <div
          onClick={() => setHeader({ ...header, active: true })}
          className="AdminNewFirstBlockOpen"
        >
          <EditIcon />
        </div>
      )}
      <div className="MainBlock">
        <div className="AdminNewsFirstBlock">
          {!!header.image.file && (
            <img
              className={"HeaderNewsImage"}
              src={
                header.image.base
                  ? // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    //@ts-ignore
                    URL.createObjectURL(header.image.file)
                  : `${imgUrl}/${header.image.file}`
              }
            />
          )}
        </div>
        <div className="CurrentNewsTitle">
          {renderHtml(header.title[locale])}
        </div>
      </div>
      <div className="AdminNewSecondBlock">
        <div className="AdminNewSecondBlockTitle">
          {body.title.active ? (
            <div className="SecondBlockTitleEdit">
              <div className="FlexColumn">
                <EditorComponent
                  initial={bodyContentInitial.title.initial.ru}
                  setFormData={(value) => setBodyTitle(value, LocaleKeys.ru)}
                  placeholder={TextMocks.bodyTitleRu}
                  stripPastedStyles
                />
                <EditorComponent
                  initial={bodyContentInitial.title.initial.en}
                  setFormData={(value) => setBodyTitle(value, LocaleKeys.en)}
                  placeholder={TextMocks.bodyTitleEn}
                  stripPastedStyles
                />
              </div>
              <div className="SecondBlockTitleEditIcons">
                <ButtonsGroup
                  state={body}
                  isCarousel={false}
                  setStateFn={setBody}
                  idx={-1}
                />
              </div>
            </div>
          ) : (
            <div
              onClick={() => {
                setBody(
                  produce(body, (draft) => {
                    draft.title.active = true
                  })
                )
              }}
              className="NewTextEditRow FlexRow"
            >
              <div className="CurrentNewsBodyTitle">
                {renderHtml(body.title.value[locale])}
              </div>
              <div className="SecondBlockTitleEditIcon">
                <EditTextIcon />
              </div>
            </div>
          )}
        </div>

        <div className="SecondBlockBodyWrapper">
          {body.blocks.map((item, index) =>
            body.blocks[index].active ? (
              <div key={`news_block_${index}`} className="SecondBlockTitleEdit">
                <div className="FlexColumn">
                  {body.blocks[index].contentType === ContentTypes.text && (
                    <>
                      <EditorComponent
                        initial={
                          bodyContentInitial.blocks[index]?.value[LocaleKeys.ru]
                        }
                        setFormData={(value) =>
                          setBodyBlockValue(value, LocaleKeys.ru, index)
                        }
                        placeholder={TextMocks.bodyBlockNewsRu}
                      />
                      <EditorComponent
                        initial={
                          bodyContentInitial.blocks[index]?.value[LocaleKeys.en]
                        }
                        setFormData={(value) =>
                          setBodyBlockValue(value, LocaleKeys.en, index)
                        }
                        placeholder={TextMocks.bodyBlockNewsEn}
                      />
                    </>
                  )}
                  {body.blocks[index].contentType === ContentTypes.carousel && (
                    <>
                      <CarouselWithImages
                        images={
                          body.blocks[index].value.imagesFile
                            ? body.blocks[index].value.imagesFile
                            : body.blocks[index].initial.imagesBase
                        }
                        url={imgUrl}
                      />
                      <UploadImage
                        setStateFn={(files, baseFiles) => {
                          setBody(
                            produce(body, (draft) => {
                              draft.blocks[index].value.imagesFile = files
                              draft.blocks[index].value.imagesBase = baseFiles
                            })
                          )
                        }}
                        imageName={"carousel-img" + index}
                        labelName={TextMocks.loadCarouselImage}
                        imageSizeLabel={TextMocks.carouselImageDimensions}
                        multiple
                      />
                    </>
                  )}
                  {body.blocks[index].contentType === ContentTypes.video && (
                    <>
                      <Input
                        placeholder={"Ссылка на google drive"}
                        onChange={(e) => {
                          setBody(
                            produce(body, (draft) => {
                              draft.blocks[index].initial[LocaleKeys.ru] =
                                e.target.value
                              draft.blocks[index].initial[LocaleKeys.en] =
                                e.target.value
                            })
                          )
                        }}
                      />
                      <div
                        className={"Button UploadButton"}
                        onClick={() => {
                          setBody(
                            produce(body, (draft) => {
                              draft.blocks[index].value[LocaleKeys.ru] =
                                draft.blocks[index].initial[LocaleKeys.ru]
                              draft.blocks[index].value[LocaleKeys.en] =
                                draft.blocks[index].initial[LocaleKeys.en]
                            })
                          )
                        }}
                      >
                        {TextMocks.loadButton}
                      </div>
                      {renderVideo(body.blocks[index].value[locale])}
                    </>
                  )}
                </div>
                <div className="SecondBlockTitleEditIcons">
                  <ButtonsGroup
                    state={body}
                    isCarousel={
                      body.blocks[index].contentType === ContentTypes.carousel
                    }
                    setStateFn={setBody}
                    idx={index}
                  />
                </div>
              </div>
            ) : (
              <>
                <div className="FlexRow">
                  {body.blocks[index].contentType === ContentTypes.text && (
                    <div className="CurrentNewsBodyText">
                      {renderHtml(body.blocks[index].value[locale])}
                    </div>
                  )}
                  {body.blocks[index].contentType === ContentTypes.carousel && (
                    <CarouselWithImages
                      images={
                        body.blocks[index].value.imagesFile
                          ? body.blocks[index].value.imagesFile
                          : body.blocks[index].value.imagesBase
                      }
                      url={imgUrl}
                    />
                  )}
                  {body.blocks[index].contentType === ContentTypes.video &&
                    renderVideo(body.blocks[index].value[locale])}
                  <div
                    className="SecondBlockBlocksEditIcon"
                    onClick={() => {
                      setBody(
                        produce(body, (draft) => {
                          draft.blocks[index].active = true
                        })
                      )
                    }}
                  >
                    <EditTextIcon />
                  </div>
                  <br />
                </div>
              </>
            )
          )}
          <div className="SecondBlockBodyAddBlock">
            <div className="PlusIcon">
              <PlusIcon
                onClick={() => {
                  setBody(
                    produce(body, (draft) => {
                      draft.blocks.push({
                        active: true,
                        value: newBlock,
                        initial: newBlock,
                        contentType: bodyContentType,
                      })
                    })
                  )
                }}
              />
            </div>
            <div>
              <Select
                defaultValue={bodyContentType}
                className="AdminNewsSelect"
                onChange={(e: ContentTypes) => {
                  setBodyContentType(e)
                }}
              >
                <Select.Option value={ContentTypes.text}>
                  {TextMocks.text}
                </Select.Option>
                <Select.Option value={ContentTypes.carousel}>
                  {TextMocks.carousel}
                </Select.Option>
                <Select.Option value={ContentTypes.video}>
                  {TextMocks.video}
                </Select.Option>
              </Select>
            </div>
          </div>
        </div>
      </div>
      <div className="AdminPreviewBlock">
        <UploadImage
          setStateFn={(files, baseFiles) => {
            setPreview(
              produce(preview, (draft) => {
                draft.file = files[0]
                draft.base = baseFiles[0]
              })
            )
          }}
          imageName={"preview-img"}
          labelName={"Загрузить превью"}
          imageSizeLabel={"560×747"}
        />
        <div className="NewsListItem">
          {!!preview.file && !!preview.base && (
            <img
              className="AdminNewsPreviewImage"
              alt="not fount"
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              //@ts-ignore
              src={URL.createObjectURL(preview.file)}
            />
          )}
          {!!preview.file && !preview.base && (
            <div className="NewsListItemImgWrapper">
              <div
                className="NewsListItemImg"
                style={{
                  backgroundImage: `url(${imgUrl}/${preview.file})`,
                }}
              />
            </div>
          )}
          <div className="NewsListItemDate">{dayDesc}</div>
          <div className="NewsListItemTitle">
            {renderHtml(header.title[locale])}
          </div>
        </div>
      </div>
      <div className="AdminNewFooter FlexRow">
        <label
          className="Button SaveButton"
          onClick={() => {
            if (!disabledSave) save(status.publish, status.visible)
            else {
              setSaveStatus({
                ...saveStatus,
                success: SaveStatusTypes.validate,
              })
            }
          }}
        >
          {TextMocks.save}
        </label>
        {!status.publish && (
          <label
            className="Button SaveAndUploadButton"
            onClick={() => {
              if (!disabledSave) save(true, status.visible)
              else {
                setSaveStatus({
                  ...saveStatus,
                  success: SaveStatusTypes.validate,
                })
              }
            }}
          >
            {TextMocks.saveAndPublish}
          </label>
        )}
        {!status.publish && (
          <Select
            defaultValue={newsType}
            className="AdminNewsTypeSelect"
            onChange={(e: NewsType) => {
              setNewsType(e)
            }}
          >
            <Select.Option value={NewsType.news}>
              {TextMocks.news}
            </Select.Option>
            <Select.Option value={NewsType.charity}>
              {TextMocks.charity}
            </Select.Option>
          </Select>
        )}
      </div>

      {(saveStatus.pending || !!saveStatus.success) && (
        <div className="SaveAlert">
          <div className="SaveAlertModal GridCenter">
            <div className="SaveAlertModalStatus">
              {saveStatus.pending && (
                <div className="SaveAlertPending blink">
                  <PendingIcon />
                </div>
              )}
              {!saveStatus.pending &&
                !!saveStatus.success &&
                saveStatus.success !== SaveStatusTypes.validate &&
                saveStatus.success !== SaveStatusTypes.error && (
                  <div className="FlexRow SaveAlertSuccess">
                    <SuccessIcon />
                    <div>
                      {saveStatus.success === SaveStatusTypes.save
                        ? TextMocks.successCreation
                        : TextMocks.successCreationPublish}
                    </div>
                  </div>
                )}
              {!saveStatus.pending &&
                saveStatus.success === SaveStatusTypes.error && (
                  <div className="SaveAlertError">
                    <div className="FlexRow SaveAlertError">
                      <ErrorIcon />
                      <span>
                        {TextMocks.error}
                        <br /> {TextMocks.errorCreation}
                      </span>
                    </div>
                  </div>
                )}
              {!saveStatus.pending &&
                saveStatus.success === SaveStatusTypes.validate && (
                  <div className="SaveAlertError">
                    <div className="FlexRow SaveAlertError">
                      <ErrorIcon />
                      <span>
                        {TextMocks.error}
                        <br /> {TextMocks.errorFields}
                      </span>
                    </div>
                  </div>
                )}
              {saveStatus.success === SaveStatusTypes.validate && (
                <div className="SaveAlertValidate">
                  {!header.image.base && !header.image.file && (
                    <span>{TextMocks.imageHeader}</span>
                  )}
                  {!checkHtmlString(header.title.ru) && (
                    <span>{TextMocks.headerTitleRU}</span>
                  )}
                  {!checkHtmlString(header.title.en) && (
                    <span>{TextMocks.headerTitleEN}</span>
                  )}
                  {!checkHtmlString(body.title.value.ru) && (
                    <span>{TextMocks.bodyTitleRu}</span>
                  )}
                  {!checkHtmlString(body.title.value.en) && (
                    <span>{TextMocks.bodyTitleEn}</span>
                  )}
                  {!body.blocks.length && <span>{TextMocks.text}</span>}
                  {!preview.file && !preview.base && (
                    <span>{TextMocks.imagePreview}</span>
                  )}
                </div>
              )}
              {!!saveStatus.success && !saveStatus.pending && (
                <div className="FlexRow SaveAlertButtons">
                  <div
                    className="Button UploadButton"
                    onClick={() =>
                      setSaveStatus({ pending: false, success: undefined })
                    }
                  >
                    {TextMocks.close}
                  </div>
                  {saveStatus.success !== SaveStatusTypes.validate && (
                    <div
                      className="Button UploadButton"
                      onClick={() => history.push(`/admin`)}
                    >
                      {TextMocks.redirectToMain}
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
          <div className="SaveAlertOverlay" />
        </div>
      )}
    </div>
  )
}

export default AdminNew
