import React from "react"
import { CarouselWithImagesProps } from "./types"
import { Carousel } from "../../../Components/Carousel"
import './styles.css'

export const CarouselWithImages: React.FC<CarouselWithImagesProps> = ({ images, url }) => {
  let res = []
  if (images && images.length > 0) {
    const isFileList = !Array.isArray(images)
    for (let i = 0; i < images.length; i++) {
      if (images[i]) {
        res.push(
          <img
            src={isFileList ? URL.createObjectURL(images[i]) : `${url}/${images[i]}`}
          />
        )
      }
    }
  }
  return <Carousel>
    {res}
  </Carousel>
}