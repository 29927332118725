import React, { useState } from "react"
import requestApi from "api/request"
import { ReactComponent as AuthLogo } from "icons/duck_logo.svg"
import { ReactComponent as ShowIcon } from "./show.svg"
import { ReactComponent as HideIcon } from "./hide.svg"

const Login = ({ setAuth }: { setAuth: (data: boolean) => void }) => {
  const [showPass, toggleShowPass] = useState<string>(`password`)
  const [authData, setAuthData] = useState<{
    username: undefined | string
    password: undefined | string
    pending: boolean
    error: string
  }>({ username: undefined, password: undefined, error: ``, pending: false })
  return (
    <div className="LoginPage">
      <div className="LoginCenterPosition">
        <div className="LoginTitle">
          <AuthLogo />
        </div>
        <div className="LoginModal">
          <div className="LoginModalLabel">Авторизация</div>
          <div className="LoginInput LoginName">
            <input
              value={authData.username}
              placeholder="Логин"
              onChange={({ target: { value } }) => {
                setAuthData({
                  ...authData,
                  username: value || undefined,
                  error: ``,
                })
              }}
            />
          </div>
          <div className="LoginInput LoginPassword">
            <input
              value={authData.password}
              type={showPass}
              placeholder="Пароль"
              onChange={({ target: { value } }) => {
                setAuthData({
                  ...authData,
                  password: value || undefined,
                  error: ``,
                })
              }}
            />
            <div
              className="LoginPasswordGlass"
              onClick={() => {
                if (showPass === `password`) toggleShowPass(`text`)
                if (showPass === `text`) toggleShowPass(`password`)
              }}
            >
              {showPass === `password` ? <HideIcon /> : <ShowIcon />}
            </div>
          </div>
          <div className="LoginError">{authData.error}</div>
          <div
            className={`LoginButtonWrapper ${
              authData.pending ? `SaveButtonDisabled` : ``
            }`}
            onClick={() => {
              if (!authData.pending) {
                setAuthData({ ...authData, pending: true })
                requestApi({
                  url: `/auth/login`,
                  method: `POST`,
                  requestBody: {
                    username: authData.username,
                    password: authData.password,
                  },
                  withToken: false,
                })
                  .then(({ data: { token } }) => {
                    localStorage.setItem(`token`, token)
                    setAuthData({ ...authData, pending: false })
                    setAuth(true)
                  })
                  .catch((error) => {
                    if (
                      error.response &&
                      error.response.data &&
                      error.response.data.message
                    ) {
                      setAuthData({
                        ...authData,
                        error: error.response.data.message,
                        pending: false,
                      })
                    }
                  })
              }
            }}
          >
            Войти
          </div>
        </div>
      </div>
    </div>
  )
}

export default Login
