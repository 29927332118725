import { useState, useCallback } from "react"
import axios, { Method } from "axios"

export const getImgUrl = () => {
  const port = window.location.port
  const domain = window.location.hostname
  const protocol = window.location.protocol
  return `${protocol}//${domain}${port ? `:${port}` : ``}/img`
}

export const useNow = () => {
  const [timestamp, setTimestamp] = useState(Date.now())

  const refresh: () => void = useCallback(() => setTimestamp(Date.now()), [])

  return [timestamp, refresh]
}

type RequestType = {
  url: string
  method?: Method | undefined
  requestBody?: any
  withToken: boolean
  logOut?: () => void
}

const requestApi = ({
  url,
  method,
  requestBody,
  withToken,
  logOut,
}: RequestType) => {
  const token = localStorage.getItem(`token`) || undefined
  const addToken = withToken ? { token: token } : {}
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return axios({
    url: `${process.env.REACT_APP_AUTH_URL}${url}`,
    method: method || `GET`,
    data: requestBody || undefined,
    withCredentials: true,
    headers: {
      ...addToken,
      "Access-Control-Allow-Credentials": true,
    },
  }).catch((error) => {
    if (
      addToken &&
      logOut &&
      error &&
      error.response &&
      error.response.status &&
      error.response.status === 401
    ) {
      logOut()
    }
    throw error
  })
}

export default requestApi
