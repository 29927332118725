export enum NewsType {
  news = 'news',
  charity = 'charity'
}

export interface NewsProps {
  type: NewsType
}

export type NewsList = {
  id: number
  title: { en: string; ru: string }
  date: string
  preview: string
}