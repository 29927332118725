import { NewsType } from "../../../News/types"
import { Method } from "axios"

export enum ModalDataStatus {
  delete = 'delete',
  publish = 'publish',
  show = 'show',
  hide = 'hide',
  empty = ''
}

export type NewBack = {
  id: number
  title: { ru: string; en: string }
  date: string
  preview: string
  publish: boolean
  visible: boolean,
  type: NewsType
}

export type NewData = {
  id: number | null
  label: null | string
  type: ModalDataStatus
  pending: boolean
  value: undefined | boolean
}

export type ModalMapData = {
  [key in ModalDataStatus]: {
    title: string
    button: string
    buttonClass: string
    method: Method
    url: string
  }
}