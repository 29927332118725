import React from "react"
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom"
import Admin from "Pages/Admin"
import Main from "Pages/Main"
import News from "Pages/News"
import CurrentNew from "Pages/News/Current"
import Info from "Pages/Info"
import Error from "Pages/Errors"
import "./App.css"
import { NewsType } from "./Pages/News/types"
import Brands from "./Pages/Brands";

const App = () => {
  return (
    <div className="App">
      <BrowserRouter>
        <Switch>
          <Route exact path="/">
            <Main />
          </Route>
          <Route exact path="/news">
            <News type={NewsType.news}/>
          </Route>
          <Route exact path="/charity">
            <News type={NewsType.charity}/>
          </Route>
          {/*TODO расоментить после того как будут данные брендов*/}
          {/*<Route exact path="/brands">*/}
          {/*  <Brands/>*/}
          {/*</Route>*/}
          <Route path="/news/:id">
            <CurrentNew />
          </Route>
          <Route exact path="/charity/:id">
            <CurrentNew />
          </Route>
          <Route exact path="/404">
            <Error />
          </Route>
          <Route exact path="/info">
            <Info />
          </Route>
          <Admin />
          <Redirect to="/" />
        </Switch>
      </BrowserRouter>
    </div>
  )
}

export default App
