import React, { useEffect, useMemo, useState } from "react"
import { ReactComponent as PlusIcon } from "Pages/Admin/Main/plus.svg"

import { ReactComponent as ShowIcon } from "Pages/Admin/Login/show.svg"
import { ReactComponent as HideIcon } from "Pages/Admin/Login/hide.svg"
import { useHistory } from "react-router-dom"
import requestApi, { getImgUrl, useNow } from "api/request"
import { renderHtml } from "../../../../utils/parseHtml"
import { NewsType } from "../../../News/types"
import { TextMocks } from "../../New/consts"
import { Select } from "../../../../Components/Select"
import { ModalDataStatus, NewBack, NewData } from "./types"
import { modalDataMap } from "./consts"

const AdminNews = ({ logOut }: { logOut: () => void }) => {
  const history = useHistory()
  const [timestamp, refresh] = useNow()
  const initialNew: NewData = {
    id: null,
    label: null,
    type: ModalDataStatus.empty,
    pending: false,
    value: undefined
  }
  const [newModal, setModalData] = useState<NewData>(initialNew)
  const [newsType, setNewsType] = useState(NewsType.charity)
  const [newsList, setNewsList] = useState<{
    pending: boolean
    data: NewBack[]
  }>({ pending: false, data: [] })

  useEffect(() => {
    setNewsList({ ...newsList, pending: true })
    requestApi({
      url: `/news/all`,
      method: `POST`,
      withToken: true,
      logOut
    })
      .then(({ data }: { data: NewBack[] }) => {
        setNewsList({ pending: false, data: data.filter(el => el.type === newsType) })
      })
      .catch(() => setNewsList({ ...newsList, pending: false }))
  }, [timestamp, newsType])

  const imgUrl: string = getImgUrl()

  const modalData = useMemo(() => modalDataMap[newModal.type], [newModal.type])
  return (
    <div>
      <div className="AdminTitle AdminBodyTitle">
        <Select defaultValue={newsType} className="AdminAllNewsSelect"
                onChange={(e: NewsType) => {
                  setNewsType(e)
                }}
                loading={newsList.pending}
        >
          <Select.Option value={NewsType.news}>{TextMocks.news}</Select.Option>
          <Select.Option value={NewsType.charity}>{TextMocks.charity}</Select.Option>
        </Select>
      </div>
      <div className="NewsList">
        <div className="NewsListAddItemWrapper">
          <div
            className="NewsListItemImg NewsListAddItem"
            onClick={() => history.push(`/admin/new`)}
          >
            <PlusIcon />
          </div>
        </div>
        {newsList.data.map((item, key) => {
          return (
            <div key={`news_item_${item.id}`}>
              <div
                className={`NewsListItem NewsListItemShow ${
                  !item.visible ? `NewsListDisabled` : ``
                }`}
                onClick={() => history.push(`/admin/${item.id}`)}
              >
                <div className="NewsListItemImgWrapper">
                  <div
                    className="NewsListItemImg"
                    style={{
                      backgroundImage: `url(${imgUrl}/${item.preview})`
                    }}
                  />
                </div>
                <div className="NewsListItemDate">{item.date}</div>
                <div className="NewsListItemTitle">{renderHtml(item.title.ru)}</div>
              </div>
              <div className="FlexRow">
                {!item.publish && (
                  <div
                    onClick={() =>
                      setModalData({
                        ...newModal,
                        id: item.id,
                        label: item.title.ru,
                        type: ModalDataStatus.publish,
                        value: true
                      })
                    }
                    className="Button AdminNewToolsButton  AdminNewToolsPublish"
                  >
                    Опубликовать
                  </div>
                )}
                {!!item.publish && (
                  <div
                    onClick={() =>
                      setModalData({
                        ...newModal,
                        id: item.id,
                        label: item.title.ru,
                        type: item.visible ? ModalDataStatus.hide : ModalDataStatus.show,
                        value: !item.visible
                      })
                    }
                    className="Button AdminNewToolsButton AdminNewToolsVisible FlexRow"
                  >
                    <div>{item.visible ? `Скрыть` : `Отобразить`}</div>
                    {item.visible ? <HideIcon /> : <ShowIcon />}
                  </div>
                )}
                <div
                  onClick={() =>
                    setModalData({
                      ...newModal,
                      id: item.id,
                      label: item.title.ru,
                      type: ModalDataStatus.delete
                    })
                  }
                  className="Button AdminNewToolsButton AdminNewToolsDelete"
                >
                  Удалить
                </div>
              </div>
            </div>
          )
        })}
      </div>
      {!!newModal.id && (
        <div>
          <div className="DeleteModal">
            <div className="DeleteModalTitle">{renderHtml(modalData.title)}</div>
            <div className="DeleteModalNewLabel">{renderHtml(newModal.label)}</div>
            <div className="DeleteModalButtons">
              <div className="FlexRow">
                <div
                  onClick={() => {
                    setModalData({ ...newModal, pending: true })
                    requestApi({
                      url: `${modalData.url}${newModal.id}`,
                      method: modalData.method,
                      withToken: true,
                      logOut,
                      requestBody: { value: newModal.value }
                    })
                      .then(() => {
                        setModalData(initialNew)
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        refresh()
                      })
                      .catch(() => setModalData(initialNew))
                  }}
                  className={`Button AdminNewToolsButton ${modalData.buttonClass}`}
                >
                  {modalData.button}
                </div>
                <div
                  onClick={() => setModalData(initialNew)}
                  className="Button AdminNewToolsButton AdminNewToolsVisible"
                >
                  Отмена
                </div>
              </div>
            </div>
          </div>
          <div className="DeleteOverlay" />
        </div>
      )}
    </div>
  )
}

export default AdminNews
