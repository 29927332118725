import React, { useState } from "react"
import Menu from "Components/Menu"
import languageConfig from "./local"
import Footer from "Components/Footer"
import FadeInSection from "Components/FadeInSection"

// black block icons
import { ReactComponent as SunIcon } from "./icons/sun.svg"
import { ReactComponent as AtomIcon } from "./icons/atom.svg"
import { ReactComponent as TargetIcon } from "./icons/target.svg"
import { ReactComponent as GlobeIcon } from "./icons/globe.svg"

// black and white block icons
import { ReactComponent as PieIcon } from "./icons/pie.svg"
import { ReactComponent as HexagonIcon } from "./icons/hexagon.svg"
import { ReactComponent as FourthsIcon } from "./icons/fourths.svg"
import { ReactComponent as CrossArrowIcon } from "./icons/cross _arrows.svg"

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import main_video from "./icons/main_video.mp4"
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import main_video_mobile from "./icons/main_video_mobile.mp4"
import { LocaleKeys } from "../Admin/New/consts"
import { getLocaleFromLs } from "../../utils/localStorage"

const Main = () => {
  const [currentLanguage, changeCurrentLanguage] = useState<LocaleKeys>(getLocaleFromLs)

  const isSmallMobile = window.innerWidth < 375
  const isDesktop = window.innerWidth > 1024

  return (
    <div className="MainPage">
      <div className="MainBlock">
        <div className="MainBlockImg">
          <video
            playsInline
            autoPlay
            muted
            loop
            poster="./icons/main_block.jpg"
            id="bgvid"
          >
            <source
              src={isDesktop ? main_video : main_video_mobile}
              type="video/mp4"
            />
          </video>
        </div>
        <Menu
          currentLanguage={currentLanguage}
          changeLanguage={changeCurrentLanguage}
        />
        <div className="MainBlockTitle ">
          <div className="MainBlockTitleUpper">
            Golden
            <br /> Duck
          </div>
          <div className="MainBlockTitleDescription">
            {languageConfig[currentLanguage].titleDescription.first}
            <br />
            {languageConfig[currentLanguage].titleDescription.second}
            <br />
            {languageConfig[currentLanguage].titleDescription.third}
          </div>
        </div>
      </div>
      {/* White block - strategy */}
      <div className="WhiteBlock">
        <div className="RightBlock">
          <FadeInSection>
            <div className="WhiteBlockTitle">
              {isSmallMobile && currentLanguage == `ru`
                ? languageConfig[currentLanguage].whiteBox.mobile_title
                : languageConfig[currentLanguage].whiteBox.title}
            </div>
          </FadeInSection>
          <div className="FlexRow WhiteBlockRow">
            <FadeInSection>
              <div className="WhiteBlockItem WhiteBlockTextLabel">
                <div className="WhiteBlockFirstTextLabel">
                  {languageConfig[currentLanguage].whiteBox.block_one.label}
                </div>
              </div>
            </FadeInSection>
            <FadeInSection>
              <div className="WhiteBlockItem FlexColumn">
                <div className="WhiteBlockTextLabel">
                  {languageConfig[currentLanguage].whiteBox.block_two.label}
                </div>
                <div className="WhiteBlockText">
                  {languageConfig[currentLanguage].whiteBox.block_two.text}
                </div>
              </div>
            </FadeInSection>
          </div>
          <div className="FlexRow WhiteBlockRow">
            <FadeInSection>
              <div className="WhiteBlockItem FlexColumn">
                <div className="WhiteBlockTextLabel">
                  {languageConfig[currentLanguage].whiteBox.block_three.label}
                </div>
                <div className="WhiteBlockText">
                  {languageConfig[currentLanguage].whiteBox.block_three.text}
                </div>
              </div>
            </FadeInSection>
            <FadeInSection>
              <div className="WhiteBlockItem FlexColumn">
                <div className="WhiteBlockTextLabel">
                  {languageConfig[currentLanguage].whiteBox.block_four.label}
                </div>
                <div className="WhiteBlockText">
                  {languageConfig[currentLanguage].whiteBox.block_four.text}
                </div>
              </div>
            </FadeInSection>
          </div>
        </div>
      </div>
      {/* Black block - principles */}
      <div className="BlackBlock">
        <div className="BlackBlockTitle">
          {languageConfig[currentLanguage].blackBox.title[0]} <br />
          {languageConfig[currentLanguage].blackBox.title[1]}
        </div>
        <div className="BlackBlockRight">
          <div className="BlackBlockRow">
            <FadeInSection>
              <div className="BlackBlockItem">
                <SunIcon />
                <div className="BlackBlockItemLabel">
                  {languageConfig[currentLanguage].blackBox.block_one.label}
                </div>
                <div className="BlackBlockItemText">
                  {languageConfig[currentLanguage].blackBox.block_one.text}
                </div>
              </div>
            </FadeInSection>
            <FadeInSection>
              <div className="BlackBlockItem">
                <AtomIcon />
                <div className="BlackBlockItemLabel">
                  {languageConfig[currentLanguage].blackBox.block_two.label}
                </div>
                <div className="BlackBlockItemText">
                  {languageConfig[currentLanguage].blackBox.block_two.text}
                </div>
              </div>
            </FadeInSection>
          </div>
          <div className="BlackBlockRow">
            <FadeInSection>
              <div className="BlackBlockItem">
                <TargetIcon />
                <div className="BlackBlockItemLabel">
                  {languageConfig[currentLanguage].blackBox.block_three.label}
                </div>
                <div className="BlackBlockItemText">
                  {languageConfig[currentLanguage].blackBox.block_three.text}
                </div>
              </div>
            </FadeInSection>
            <FadeInSection>
              <div className="BlackBlockItem">
                <GlobeIcon />
                <div className="BlackBlockItemLabel">
                  {languageConfig[currentLanguage].blackBox.block_four.label}
                </div>
                <div className="BlackBlockItemText">
                  {languageConfig[currentLanguage].blackBox.block_four.text}
                </div>
              </div>
            </FadeInSection>
          </div>
        </div>
      </div>
      {/* Orange block */}
      <div className="OrangeBlock">
        <div className="OrangeBlockTitle">
          {languageConfig[currentLanguage].orangeBox.title}
        </div>
        <FadeInSection>
          <div className="OrangeBlockLabel">
            {isDesktop ? (
              languageConfig[currentLanguage].orangeBox.subtitle.desktop[0]
            ) : (
              <div>
                {
                  languageConfig[currentLanguage].orangeBox.subtitle
                    .smallMobile[0]
                }
                <br />
                {
                  languageConfig[currentLanguage].orangeBox.subtitle
                    .smallMobile[1]
                }
              </div>
            )}
            {isDesktop && <br />}
            {isDesktop &&
              languageConfig[currentLanguage].orangeBox.subtitle.desktop[1]}
            {isDesktop && <br />}
            {isDesktop ? (
              languageConfig[currentLanguage].orangeBox.subtitle.desktop[2]
            ) : (
              <div>
                {languageConfig[currentLanguage].orangeBox.subtitle.mobile[2]}
                <br />
                {isSmallMobile || currentLanguage === `ru` ? (
                  <div>
                    {
                      languageConfig[currentLanguage].orangeBox.subtitle
                        .smallMobile[3]
                    }
                    <br />
                    {
                      languageConfig[currentLanguage].orangeBox.subtitle
                        .smallMobile[4]
                    }
                  </div>
                ) : (
                  languageConfig[currentLanguage].orangeBox.subtitle.mobile[3]
                )}
              </div>
            )}
          </div>
        </FadeInSection>
        <div className="OrangeBlockRight">
          <div className="OrangeBlockRow">
            <FadeInSection>
              <div className="OrangeBlockItem">
                <div className="OrangeBlockTeamItem OrangeBlockTeamItemOne">
                  <div className="OrangeBlockTeamItemData">
                    <div className="OrangeBlockTeamItemDataLabel">
                      {languageConfig[currentLanguage].orangeBox.team.one[0]}
                    </div>
                    <div className="OrangeBlockTeamItemDataText">
                      {languageConfig[currentLanguage].orangeBox.team.one[1]}
                    </div>
                  </div>
                </div>
              </div>
            </FadeInSection>
            <FadeInSection>
              <div className="OrangeBlockItem">
                <div className="OrangeBlockTeamItem OrangeBlockTeamItemTwo">
                  <div className="OrangeBlockTeamItemData">
                    <div className="OrangeBlockTeamItemDataLabel">
                      {languageConfig[currentLanguage].orangeBox.team.two[0]}
                    </div>
                    <div className="OrangeBlockTeamItemDataText">
                      {languageConfig[currentLanguage].orangeBox.team.two[1]}
                    </div>
                  </div>
                </div>
              </div>
            </FadeInSection>
          </div>
          <div className="OrangeBlockRow">
            <FadeInSection>
              <div className="OrangeBlockItem">
                <div className="OrangeBlockTeamItem OrangeBlockTeamItemThree">
                  <div className="OrangeBlockTeamItemData">
                    <div className="OrangeBlockTeamItemDataLabel">
                      {languageConfig[currentLanguage].orangeBox.team.three[0]}
                    </div>
                    <div className="OrangeBlockTeamItemDataText">
                      {languageConfig[currentLanguage].orangeBox.team.three[1]}
                    </div>
                  </div>
                </div>
              </div>
            </FadeInSection>
            <FadeInSection>
              <div className="OrangeBlockItem">
                <div className="OrangeBlockTeamItem OrangeBlockTeamItemFour">
                  <div className="OrangeBlockTeamItemData">
                    <div className="OrangeBlockTeamItemDataLabel">
                      {languageConfig[currentLanguage].orangeBox.team.four[0]}
                      <br />
                      {languageConfig[currentLanguage].orangeBox.team.four[1]}
                    </div>
                    <div className="OrangeBlockTeamItemDataText">
                      {languageConfig[currentLanguage].orangeBox.team.four[2]}
                    </div>
                  </div>
                </div>
              </div>
            </FadeInSection>
          </div>
          <div className="OrangeBlockRow">
            <FadeInSection>
              <div className="OrangeBlockItem">
                <div className="OrangeBlockTeamItem OrangeBlockTeamItemFive">
                  <div className="OrangeBlockTeamItemData">
                    <div className="OrangeBlockTeamItemDataLabel">
                      {languageConfig[currentLanguage].orangeBox.team.five[0]}
                    </div>
                    <div className="OrangeBlockTeamItemDataText">
                      {languageConfig[currentLanguage].orangeBox.team.five[1]}
                    </div>
                  </div>
                </div>
              </div>
            </FadeInSection>
          </div>
        </div>
      </div>
      {/* Black and White Block */}
      <div className="BlackBlock" style={{ backgroundColor: `#F5F5F5` }}>
        {isDesktop ? (
          <div className="BlackBlockTitle" style={{ color: `#000` }}>
            {languageConfig[currentLanguage].secondWhiteBox.title[0]} <br />
            {languageConfig[currentLanguage].secondWhiteBox.title[1]}
          </div>
        ) : (
          <div className="BlackBlockTitle" style={{ color: `#000` }}>
            {languageConfig[currentLanguage].secondWhiteBox.titleMobile[0]}{" "}
            <br />
            {languageConfig[currentLanguage].secondWhiteBox.titleMobile[1]}{" "}
            <br />
            {languageConfig[currentLanguage].secondWhiteBox.titleMobile[2]}
          </div>
        )}
        <div className="BlackBlockRight">
          <div className="BlackBlockRow">
            <FadeInSection>
              <div className="BlackBlockItem">
                <PieIcon />
                <div className="BlackBlockItemLabel" style={{ color: `#000` }}>
                  {
                    languageConfig[currentLanguage].secondWhiteBox.block_one
                      .label
                  }
                </div>
                <div
                  className="BlackBlockItemText"
                  style={{ color: `#949494` }}
                >
                  {
                    languageConfig[currentLanguage].secondWhiteBox.block_one
                      .text
                  }
                </div>
              </div>
            </FadeInSection>
            <FadeInSection>
              <div className="BlackBlockItem">
                <HexagonIcon />
                <div className="BlackBlockItemLabel" style={{ color: `#000` }}>
                  {
                    languageConfig[currentLanguage].secondWhiteBox.block_two
                      .label
                  }
                </div>
                <div
                  className="BlackBlockItemText"
                  style={{ color: `#949494` }}
                >
                  {
                    languageConfig[currentLanguage].secondWhiteBox.block_two
                      .text
                  }
                </div>
              </div>
            </FadeInSection>
          </div>
          <div className="BlackBlockRow">
            <FadeInSection>
              <div className="BlackBlockItem">
                <FourthsIcon />
                <div className="BlackBlockItemLabel" style={{ color: `#000` }}>
                  {
                    languageConfig[currentLanguage].secondWhiteBox.block_three
                      .label
                  }
                </div>
                <div
                  className="BlackBlockItemText"
                  style={{ color: `#949494` }}
                >
                  {
                    languageConfig[currentLanguage].secondWhiteBox.block_three
                      .text
                  }
                </div>
              </div>
            </FadeInSection>
            <FadeInSection>
              <div className="BlackBlockItem">
                <CrossArrowIcon />
                <div className="BlackBlockItemLabel" style={{ color: `#000` }}>
                  {
                    languageConfig[currentLanguage].secondWhiteBox.block_four
                      .label
                  }
                </div>
                <div
                  className="BlackBlockItemText"
                  style={{ color: `#949494` }}
                >
                  {
                    languageConfig[currentLanguage].secondWhiteBox.block_four
                      .text
                  }
                </div>
              </div>
            </FadeInSection>
          </div>
        </div>
      </div>
      {/* Footer */}
      <Footer currentLanguage={currentLanguage} />
    </div>
  )
}

export default Main
