import React from "react"
import { ButtonsGroupProps } from "./types"
import { ReactComponent as DoneIcon } from "./icons/done.svg"
import produce from "../../../utils/immer"
import { ReactComponent as CancelIcon } from "./icons/cancel.svg"
import { ReactComponent as TrashIcon } from "./icons/trash.svg"
import { checkHtmlString } from "./helpers"
import "./styles.css"

export const ButtonsGroup: React.FC<ButtonsGroupProps> = ({ state, setStateFn, idx, isCarousel }) => {
  let anyValue = false
  let anyInitialValue = false
  const validIdx = idx !== -1

  if (validIdx) {
    if (isCarousel) {
      anyValue = !!state.blocks[idx].value.imagesFile?.length || !!state.blocks[idx].value.imagesBase?.length
      anyInitialValue = !!state.blocks[idx].value.imagesFile?.length && !!state.blocks[idx].initial.imagesBase?.length
    } else {
      anyValue = checkHtmlString(state.blocks[idx].value.en) && checkHtmlString(state.blocks[idx].value.ru)
      anyInitialValue = checkHtmlString(state.blocks[idx].initial.en) && checkHtmlString(state.blocks[idx].initial.ru)
    }
  } else {
    anyValue = checkHtmlString(state.title.value.en) && checkHtmlString(state.title.value.ru)
    anyInitialValue = checkHtmlString(state.title.initial.en) && checkHtmlString(state.title.initial.ru)
  }

  return <>
    {anyValue &&
      <DoneIcon
        className="DoneIcon"
        onClick={() => {
          setStateFn(produce(state, draft => {
            if (validIdx) {
              draft.blocks[idx].initial = state.blocks[idx].value
              draft.blocks[idx].active = false
            } else {
              draft.title.initial = state.title.value
              draft.title.active = false
            }
          }))
        }}
      />
    }
    <CancelIcon
      className="CancelIcon"
      onClick={() => {
        setStateFn(produce(state, draft => {

          if (validIdx) {
            if (anyInitialValue) {
              draft.blocks[idx].value = state.blocks[idx].initial
              draft.blocks[idx].active = false
            } else {
              draft.blocks.splice(idx, 1)
            }
          } else {
            draft.title.value = state.title.initial
            draft.title.active = false
          }
        }))
      }}
    />
    {validIdx && <TrashIcon
      className="TrashIcon"
      onClick={() => {
        setStateFn(produce(state, draft => {
          draft.blocks.splice(idx, 1)
        }))
      }}
    />
    }
  </>
}