import React from "react"
import {
  YMaps,
  Map,
  Placemark,
  GeolocationControl,
  ZoomControl,
} from "react-yandex-maps"

import TestImg from "./map_marger.svg"

const MapComponent = () => (
  <div className="MapComponentWrapper">
    <YMaps>
      <div>
        <Map
          defaultState={{
            center: [55.758776, 37.653846],
            zoom: 18,
          }}
          width="100%"
          height={window.innerWidth < 1024 ? `calc(100vh - 150px)` : `600px`}
        >
          <ZoomControl options={{ float: "right" }} />
          <Placemark
            iconImageHref="icons/duck_logo.svg"
            geometry={[55.758676, 37.653846]}
            options={{
              iconLayout: "default#image",
              iconImageHref: TestImg,
              iconImageSize: [58, 58],
              iconImageOffset: [-50, -18],
            }}
          />
        </Map>
      </div>
    </YMaps>
  </div>
)

export default MapComponent
