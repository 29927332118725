import React, { Dispatch, SetStateAction, useState } from "react"
import { Link, useHistory, useLocation } from "react-router-dom"
import { ReactComponent as DuckIcon } from "./duck_logo.svg"
import { ReactComponent as DuckActiveIcon } from "./duck_active_logo.svg"
import { ReactComponent as MobileDuckIcon } from "./mobile_duck_logo.svg"
import { ReactComponent as MobileDuckActiveIcon } from "./mobile_duck_active.svg"
import { ReactComponent as ToggleOpenIcon } from "./toggle_open.svg"
import { ReactComponent as ToggleCloseIcon } from "./toggle_close.svg"
import { LocaleKeys } from "../../Pages/Admin/New/consts"

const Menu = ({
  currentLanguage,
  changeLanguage,
}: {
  currentLanguage: LocaleKeys
  changeLanguage: Dispatch<SetStateAction<LocaleKeys>>
}) => {
  const history = useHistory()

  const { pathname } = useLocation()

  const redirectOnMain = () => history.push(`/`)

  const [openMenu, toggleMenu] = useState(false)
  const swapLocal = (value: string) => {
    localStorage.setItem(`local`, value)
    //TODO нормальная версия языкового пакета, например i18n
    //@ts-ignore
    changeLanguage(value)
    toggleMenu(false)
  }
  const isEn = currentLanguage === `en`
  return (
    <div>
      <div className="MenuWrapper FlexRow">
        <div className="MenuDuckWrapper" onClick={redirectOnMain}>
          <div className="UsualIcon">
            <DuckIcon />
          </div>
          <div className="ActiveIcon">
            <DuckActiveIcon />
          </div>
        </div>
        <div className="MenuMobileDuckWrapper" onClick={redirectOnMain}>
          <div className="UsualIcon">
            <MobileDuckIcon />
          </div>
          <div className="ActiveIcon">
            <MobileDuckActiveIcon />
          </div>
        </div>
        <div className="MenuItemList FlexRow">
          <div className="FlexRow">
            <Link
              className={`MenuItem ${pathname === `/` ? `MenuItemActive` : ``}`}
              to="/"
            >
              {isEn ? `About us` : `О нас`}
            </Link>
            <Link
              className={`MenuItem ${
                pathname === `/news` ? `MenuItemActive` : ``
              }`}
              to="/news"
            >
              {isEn ? `News` : `Новости`}
            </Link>
            <Link
              className={`MenuItem ${
                pathname === `/charity` ? `MenuItemActive` : ``
              }`}
              to="/charity"
            >
              {isEn ? `Responsibility` : `Ответственность`}
            </Link>
            {/*TODO расоментить после того как будут данные брендов*/}
            {/*<Link*/}
            {/*    className={`MenuItem ${*/}
            {/*        pathname === `/brands` ? `MenuItemActive` : ``*/}
            {/*    }`}*/}
            {/*    to="/brands"*/}
            {/*>*/}
            {/*  {isEn ? `Brands` : `Бренды`}*/}
            {/*</Link>*/}
            <Link
              className={`MenuItem ${
                pathname === `/info` ? `MenuItemActive` : ``
              }`}
              to="/info"
            >
              {isEn ? `Contacts` : `Контакты`}
            </Link>
            <div className="MenuItem">
              <span
                className={isEn ? `` : `MenuItemActive`}
                onClick={() => swapLocal(`ru`)}
              >
                RU
              </span>
              <span
                className={isEn ? `MenuItemActive` : ``}
                onClick={() => swapLocal(`en`)}
              >
                EN
              </span>
            </div>
          </div>
        </div>
        <div className="MobileMenuToggle" onClick={() => toggleMenu(true)}>
          {openMenu ? <ToggleCloseIcon /> : <ToggleOpenIcon />}
        </div>
      </div>
      {openMenu && (
        <div className="MobileMenuWrapper">
          <div className="FlexRow">
            <div className="MenuMobileDuckWrapper">
              <MobileDuckIcon />
            </div>
            <div className="MobileMenuToggle" onClick={() => toggleMenu(false)}>
              <ToggleCloseIcon />
            </div>
          </div>
          <div className="MobileMenuList">
            <Link
              className={`MenuItem ${pathname === `/` ? `MenuItemActive` : ``}`}
              to="/"
            >
              {isEn ? `About us` : `О нас`}
            </Link>
            <Link
              className={`MenuItem ${
                pathname === `/news` ? `MenuItemActive` : ``
              }`}
              to="news"
            >
              {isEn ? `News` : `Новости`}
            </Link>
            <Link
              className={`MenuItem ${
                pathname === `/charity` ? `MenuItemActive` : ``
              }`}
              to="/charity"
            >
              {isEn ? `Responsibility` : `Ответственность`}
            </Link>
            {/*TODO расоментить после того как будут данные брендов*/}
            {/*<Link*/}
            {/*    className={`MenuItem ${*/}
            {/*        pathname === `/brands` ? `MenuItemActive` : ``*/}
            {/*    }`}*/}
            {/*    to="/brands"*/}
            {/*>*/}
            {/*  {isEn ? `Brands` : `Бренды`}*/}
            {/*</Link>*/}
            <Link
              className={`MenuItem ${
                pathname === `/info` ? `MenuItemActive` : ``
              }`}
              to="info"
            >
              {isEn ? `Contacts` : `Контакты`}
            </Link>
            <div className="MenuItem FlexRow">
              <span onClick={() => swapLocal(`ru`)}>RU</span>
              <span onClick={() => swapLocal(`en`)}>EN</span>
            </div>
            <div className="MenuItem">
              <a href="tel:8 800 511 14 34">8 800 511 14 34</a>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default Menu
