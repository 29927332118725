const languageConfig: any = {
  ru: {
    titleDescription: {
      first: `Инвестиционная компания с опытом`,
      second: `создания и развития проектов`,
      third: `в области e-commerce`,
    },
    whiteBox: {
      title: `Мы выведем ваш бизнес на новый уровень за счёт сильной экспертизы`,
      mobile_title: `Мы выведем ваш бизнес на новый уровень за счёт сильной экспертизы`,
      block_one: {
        label: `Удобные форматы сотрудничества в зависимости от поставленных целей`,
      },
      block_two: {
        label: `Инвестирование`,
        text: `Выкупим компанию полностью или частично для совместного оперирования. Условия инвестирования обсуждаются при личной встрече`,
      },
      block_three: {
        label: `Увеличение товарооборота`,
        text: `Поможем вашей компании выйти на новый уровень товарооборота и привлечём новых поставщиков`,
      },
      block_four: {
        label: `Займ без процентов `,
        text: `Предоставим беспроцентный займ на развитие и расширение товарного ассортимента`,
      },
    },
    orangeBox: {
      title: `Команда`,
      subtitle: {
        desktop: [
          `В управляющий совет`,
          `компании входят`,
          `опытные предприниматели`,
        ],
        mobile: [
          `В управляющий`,
          `совет компании`,
          `входят опытные`,
          `предприниматели`,
        ],
        smallMobile: [
          `В управля- `,
          `ющий совет компании`,
          `входят опытные`,
          `предпринима-`,
          `тели`,
        ],
      },
      team: {
        one: [`Арсен Каспарян`, `Управляющий партнер`],
        two: [`Арман Овсепян`, `Партнер`],
        three: [`Валерий Молчанов`, `Директор по правовым вопросам`],
        four: [`Лейсан`, `Хисамутдинова`, `Директор по финансам`],
        five: [`Тимур Расулов`, `Директор по продакшену`],
      },
    },
    blackBox: {
      title: [`принципы`, `работы`],
      block_one: {
        label: `Мы знаем бизнес изнутри `,
        text: `Благодаря многолетнему опыту управления различными видами бизнеса мы, как никто другой, знаем как оптимизировать процессы так, чтобы компания начала увеличивать обороты как можно скорее`,
      },
      block_two: {
        label: `Мы сделаем ваш бизнес сильнее`,
        text: `Помимо финансовой поддержки мы проведем тщательную аналитику вашего бизнеса по маркетинговому, юридическому, контент- и дизайн-направлениям: усилим слабые стороны и еще больше укрепим сильные`,
      },
      block_three: {
        label: `Мы выбираем лучших`,
        text: `А лучшие выбирают нас! Мы заинтересованы в построении крепких партнерских отношений, которые выведут ваш бизнес на новый уровень благодаря совместной продуктивной работе`,
      },
      block_four: {
        label: `Мы развиваемся вместе с вами`,
        text: `Компания работает с партнерами в России и за рубежом и нацелена на качественное развитие. В основе ценностей компании лежит постоянное развитие экспертизы для повышения привлекательности вашего бизнеса`,
      },
    },

    secondWhiteBox: {
      title: [`Преимущества`, `для бизнеса`],
      titleMobile: [`Преимуще`, `ства для`, `бизнеса`],
      block_one: {
        label: `Аналитика`,
        text: `Наш аналитический отдел поможет выявить конкурентные преимущества для расширения товарного ассортимента и выбора правильного позиционирования на рынке`,
      },
      block_two: {
        label: `Юридические услуги и финансы `,
        text: `Cосредоточтесь на развитии своей компании, а все задачи по документообороту, бухгалтерским и финансовым отчётам оставьте нашей команде  `,
      },
      block_three: {
        label: `Маркетинг`,
        text: `Профессиональные маркетологи помогут правильно выстроить систему продаж для привлечения, удержания и возврата покупателей `,
      },
      block_four: {
        label: `Контент и дизайн`,
        text: `Стильный дизайн — важная составляющая успешных продаж. Разработаем яркий фирменный стиль, чтобы помочь вам выделиться среди конкурентов и повысить лояльность к бренду`,
      },
    },
  },
  en: {
    titleDescription: {
      first: `Investing company with an experience`,
      second: ` of setting up and developing projects`,
      third: ` in e-commerce`,
    },
    whiteBox: {
      title: `We will bring your business to a new level by taking advantage of strong expertise in law, content creating and strategy`,
      block_one: {
        label: `Comfortable formats of cooperation`,
      },
      block_two: {
        label: `Investment`,
        text: `We will fully or partly buy out a company for joint management of commodity business. Terms of investment will be discussed on a personal meeting`,
      },
      block_three: {
        label: `Increase of turnover`,
        text: `We will help your company to reach a new level in turnover and attract new suppliers`,
      },
      block_four: {
        label: `Interest-free loan`,
        text: `We will give you an interest-free loan for the development and expansion of product range`,
      },
    },
    orangeBox: {
      title: `Team`,
      subtitle: {
        desktop: [
          `Company’s Board of`,
          ` Directors consists of `,
          `experienced entrepreneurs`,
        ],
        mobile: [
          `Company’s Board`,
          `of Directors`,
          `consists of experienced`,
          `entrepreneurs`,
        ],
        smallMobile: [
          `Company’s Board`,
          `of Directors`,
          `consists of experienced`,
          `entrepre-`,
          `neurs`,
        ],
      },
      team: {
        one: [`Arsen Kasparyan`, `Managing partner`],
        two: [`Arman Ovsepyan`, `Partner`],
        three: [`Valeriy Molchanov`, `Chief legal officer`],
        four: [`Leisan`, `Khisamutdinova`, `Chief financial officer`],
        five: [`Timur Rasulov`, `Content production director`],
      },
    },

    blackBox: {
      title: [`Our`, ` principles`],
      block_one: {
        label: `We know business form the inside`,
        text: `Thanks to the experience in management of different kind of companies accumulated over the years, we know as nobody else how to improve processes in order to increase company’s turnover as soon as possible 
`,
      },
      block_two: {
        label: `We will make your business stronger`,
        text: `In addition to financial support we will make a detailed analysis of your business through marketing, law, content and design directions: we will strengthen weaknesses and enhance strong parts even more
`,
      },
      block_three: {
        label: `We chose the best`,
        text: `And the best choose us! We are interested in building of strong partner relationships, which will lead your business to a new level due to the effective cooperation
`,
      },
      block_four: {
        label: `We are developing with you`,
        text: `Our company works with partners within Russia and abroad and is focused on qualitative development. Company’s values based on permanent development of internal expertise in order to increase attractiveness of your business`,
      },
    },

    secondWhiteBox: {
      title: [`Advantages for`, `business`],
      titleMobile: [`Advant- `, `ages for `, ` business`],
      block_one: {
        label: `Analytics`,
        text: `Our analytical department will allow you to identify correctly competitive advantages for expanding the product range and choosing the right positioning in the market`,
      },
      block_two: {
        label: `Law services and finance`,
        text: `Focus on the development of your company while we will take care of workflow, accounting and financial reports`,
      },
      block_three: {
        label: `Marketing`,
        text: `Professional marketers will help you to build the right sales processes to attract, retain and return customers`,
      },
      block_four: {
        label: `Content and design`,
        text: `Stylish design is an important part of successful sales. We will create a bright corporate identity in order to help you stand out from the competitors and increase brand loyalty`,
      },
    },
  },
}

export default languageConfig
