import React from "react"
import {Select as AntdSelect} from "antd"
import {OptionProps, SelectProps} from "./types"
import "antd/dist/antd.css"
import './styles.css'

const Select: React.FC<SelectProps> & { Option: React.FC<OptionProps> } = (props) => {
    return <AntdSelect {...props} />
}


Select.Option = AntdSelect.Option

export default Select
