import React from "react"
import {Input as AntdInput} from "antd"
import {InputProps} from "./types"
import "antd/dist/antd.css"
import './styles.css'

const Input:React.FC<InputProps> = (props) => {
  return <AntdInput {...props}/>
}

export default Input
