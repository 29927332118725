const languageConfig: any = {
  ru: {
    title: `ИНФО`,
    titleDescription: [`Контактная информация`, `для связи с нами`],
    whiteBox: {
      title: [`Мы открыты`, `к диалогу`],
      block_one: {
        label: `Контакты`,
        text: [`Москва. Яковоапостольский переулок, 7с1`, `info@gduck.ru`],
      },
      block_two: {
        label: `Телефон`,
        text: [`8 (800) 551-14-34`, `+7 (495) 138-34-44`],
      },
    },
  },
  en: {
    title: `INFO`,
    titleDescription: [`Information to`, `contact us`],
    whiteBox: {
      title: [`WE ARE OPENED`, `FOR DIALOGUE`],
      block_one: {
        label: `Contacts`,
        text: [`7 building 1, Yakovoapostolsky lane, Moscow`, `info@gduck.ru`],
      },
      block_two: {
        label: `Phone`,
        text: [`8 (800) 551-14-34`, `+7 (495) 138-34-44`],
      },
    },
  },
}

export default languageConfig
