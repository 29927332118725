import React, { useEffect, useState } from "react"

import Menu from "Components/Menu"
import Footer from "Components/Footer"
import Media from "Components/Media"
import MapComponent from "Components/Map"

import languageConfig from "./local"
import requestApi from "api/request"
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import info_video from "./info_video.mp4"
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import info_video_mobile from "./info_video_mobile.mp4"
import { getLocaleFromLs } from "../../utils/localStorage"
import { LocaleKeys } from "../Admin/New/consts"

type ContactsType = {
  address: {
    ru: string
    en: string
  }
  mail: string
  phoneOne: string
  phoneTwo: string
  links: {
    facebook: string
    instagram: string
    whatsup: string
    telegram: string
  }
}

const Info = () => {
  const [contactsData, setContacts] = useState<ContactsType>({
    address: {
      ru: `Москва. Яковоапостольский переулок, 7с1`,
      en: `7 building 1, Yakovoapostolsky lane, Moscow`,
    },
    mail: `info@gduck.ru`,
    phoneOne: `8 800 511 14 34`,
    phoneTwo: `+7 (495) 138-34-44`,
    links: {
      facebook: ``,
      instagram: ``,
      whatsup: ``,
      telegram: ``,
    },
  })
  useEffect(() => {
    requestApi({ url: `/contacts`, method: `GET`, withToken: false }).then(
      ({ data }: { data: ContactsType }) => setContacts(data)
    )
  }, [])
  const [currentLanguage, changeCurrentLanguage] = useState<LocaleKeys>(getLocaleFromLs())
  const isDesktop = window.innerWidth > 1024
  return (
    <div className="InfoWrapper">
      <div className="MainBlock">
        <div className="MainBlockImg">
          <video
            playsInline
            autoPlay
            muted
            loop
            poster="./icons/main_block.jpg"
            id="bgvid"
          >
            <source
              src={isDesktop ? info_video : info_video_mobile}
              type="video/mp4"
            />
          </video>
        </div>
        <Menu
          currentLanguage={currentLanguage}
          changeLanguage={changeCurrentLanguage}
        />
        <div className="InfoBlockTitle ">
          <div className="MainBlockTitleUpper">
            {languageConfig[currentLanguage].title}
          </div>
          <div className="MainBlockTitleDescription">
            {languageConfig[currentLanguage].titleDescription[0]} <br />
            {languageConfig[currentLanguage].titleDescription[1]}
          </div>
        </div>
      </div>
      <div className="WhiteBlock">
        <div className="RightBlock">
          <div className="WhiteBlockTitle">
            {languageConfig[currentLanguage].whiteBox.title[0]}
            <br />
            {languageConfig[currentLanguage].whiteBox.title[1]}
          </div>
          <div className="FlexRow WhiteBlockRow InfoDesktopBlock">
            <div className="WhiteBlockItem">
              <div className="WhiteBlockTextLabel">
                {languageConfig[currentLanguage].whiteBox.block_one.label}
              </div>
              <div className="WhiteBlockText WhiteBlockTextAddress">
                {contactsData.address[currentLanguage]}
                <br />
                <a
                  href={`mailto:${contactsData.mail}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  {contactsData.mail}
                </a>
              </div>
              <div>
                <Media links={contactsData.links} />
              </div>
            </div>

            <div className="WhiteBlockItem FlexColumn">
              <div className="WhiteBlockTextLabel">
                {languageConfig[currentLanguage].whiteBox.block_two.label}
              </div>
              <div className="WhiteBlockText">
                <a href={`tel:${contactsData.phoneOne}`}>
                  {contactsData.phoneOne}
                </a>
                <br />
                <a href={`tel:${contactsData.phoneTwo}`}>
                  {contactsData.phoneTwo}
                </a>
              </div>
            </div>
          </div>
          <div className="InfoMobileBlock">
            <div className="InfoMobileRectangle" />
            <div className="WhiteBlockItem FlexColumn">
              <div className="WhiteBlockTextLabel WhiteBlockTextInfoLabel">
                {languageConfig[currentLanguage].whiteBox.block_one.label}
              </div>
              <div className="WhiteBlockText WhiteBlockTextAddress">
                {contactsData.address[currentLanguage]}
                <br />
                {contactsData.mail}
              </div>
              <div>
                <Media links={contactsData.links} />
              </div>
            </div>
            <div className="InfoMobileRectangle" />
            <div className="WhiteBlockItem FlexColumn">
              <div className="WhiteBlockTextLabel WhiteBlockTextInfoLabel">
                {languageConfig[currentLanguage].whiteBox.block_two.label}
              </div>
              <div className="WhiteBlockText">
                {contactsData.phoneOne}
                <br />
                {contactsData.phoneTwo}
              </div>
            </div>
          </div>
        </div>
      </div>
      <MapComponent />
      <Footer currentLanguage={currentLanguage} />
    </div>
  )
}

export default Info
