import { ModalDataStatus, ModalMapData } from "./types"

export const modalDataMap: ModalMapData = {
  [ModalDataStatus.delete]: {
    title: `Удалить новость?`,
    button: `Удалить`,
    buttonClass: `AdminNewToolsDelete`,
    method: `DELETE`,
    url: `/news/`
  },
  [ModalDataStatus.publish]: {
    title: `Опубликовать новость?`,
    button: `Опубликовать`,
    buttonClass: `AdminNewToolsPublish`,
    method: `PUT`,
    url: `/news/publish/`
  },
  [ModalDataStatus.hide]: {
    title: `Скрыть новость из ленты?`,
    button: `Скрыть`,
    buttonClass: `AdminNewToolsVisible`,
    method: `PUT`,
    url: `/news/visible/`
  },
  [ModalDataStatus.show]: {
    title: `Отобразить новость?`,
    button: `Отобразить`,
    buttonClass: `AdminNewToolsVisible`,
    method: `PUT`,
    url: `/news/visible/`
  },
  [ModalDataStatus.empty]: {
    title: ``,
    button: ``,
    buttonClass: ``,
    method: `PUT`,
    url: ``
  }
}