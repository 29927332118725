import { NewsType } from "./types"
// @ts-ignore
import news_video from "./news_video.mp4"
// @ts-ignore
import news_charity_video from "./news_charity_video.mp4"
// @ts-ignore
import news_video_mobile from "./news_video_mobile.mp4"
// @ts-ignore
import news_charity_video_mobile from "./news_charity_video_mobile.mp4"

export const videoMap = {
  news: news_video,
  charity: news_charity_video
}

export const mobileVideoMap = {
  news: news_video_mobile,
  charity: news_charity_video_mobile
}

export const languageConfig = {
  [NewsType.news]: {
    ru: {
      title: "Новости",
      titleDescription: {
        first: `Что нового в нашей компании.`,
        second: `Информация в СМИ о нас`
      },
      subTitleBox: {
        title: ``,
        mobile_title: ``,
        block_one: {
          label: ``,
          text: ``
        },
        block_two: {
          label: ``,
          text: ``
        }
      }
    },
    en: {
      title: "News",
      titleDescription: {
        first: `What's new in our company.`,
        second: `Information in the media about us`
      },
      subTitleBox: {
        title: ``,
        mobile_title: ``,
        block_one: {
          label: ``,
          text: ``
        },
        block_two: {
          label: ``,
          text: ``
        }
      }
    }
  },
  [NewsType.charity]: {
    ru: {
      title: "Забота",
      titleDescription: {
        first: `Благотворительные проекты`,
        second: ``
      },
      subTitleBox: {
        title: `важно помнить о социальной ответственности. Мы делаем то, что в наших силах, и хотим регулярно помогать тем, кто в этом нуждается`,
        mobile_title: `важно помнить о социальной ответственности. Мы делаем то, что в наших силах, и хотим регулярно помогать тем, кто в этом нуждается`,
        block_one: {
          label: `Наши ценности`,
          text: `Мы хотим развиваться не только внутри компании, но и за ее пределами, а также заботиться о планете глобально и о людях в частности.`
        },
        block_two: {
          label: `Наши проекты`,
          text: `На сегодняшний день наша команда провела уже три благотворительных мероприятия: для Кожуховского приюта для бездомных животных, школы-интерната в Ивановской области и частное мероприятие для нашего коллеги.`
        }
      }
    },
    en: {
      title: "Care",
      titleDescription: {
        first: `Charitable projects`,
        second: ``
      },
      subTitleBox: {
        title: `it is important to remember about social responsibility. We do what we can and we want to regularly help those who need it`,
        mobile_title: `it is important to remember about social responsibility. We do what we can and we want to regularly help those who need it`,
        block_one: {
          label: `Our values`,
          text: `We want to develop not only within the company, but also outside it, as well as take care of the planet globally and people in particular.`
        },
        block_two: {
          label: `Our projects`,
          text: `To date, our team has already held three charity events: for the shelter for homeless animals, a boarding school and a private event for our colleague.`
        }
      }
    }
  }
}
