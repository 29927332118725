import React from "react"
import { useHistory } from "react-router-dom"

import AdminContacts from "./Contacts"
import AdminNews from "./News"

import { ReactComponent as DuckLogo } from "icons/duck_logo.svg"

export type ContactsType = {
  address: { ru: string; en: string }
  mail: string
  phoneOne: string
  phoneTwo: string
  links: {
    instagram: string
    telegram: string
    whatsup: string
    facebook: string
  }
}

const MainAdmin = ({ logOut }: { logOut: () => void }) => {
  const history = useHistory()
  const initialContacts: ContactsType = {
    address: {
      ru: ``,
      en: ``,
    },
    mail: ``,
    phoneOne: ``,
    phoneTwo: ``,
    links: {
      instagram: ``,
      telegram: ``,
      whatsup: ``,
      facebook: ``,
    },
  }
  return (
    <div>
      <div className="AdminHeader">
        <DuckLogo />
        <div
          onClick={() => {
            localStorage.setItem(`token`, ``)
            history.push(`/`)
          }}
          className="Button AdminBackOnSite"
        >
          Вернуться на сайт
        </div>
      </div>
      <div className="AdminBody">
        <AdminNews logOut={logOut} />
      </div>
      <div className="AdminFooter">
        <div className="AdminTitle AdminFooterTitle">Контакты</div>
        <AdminContacts logOut={logOut} initial={initialContacts} />
      </div>
    </div>
  )
}

export default MainAdmin
