import React, { useEffect } from "react"
import { useHistory } from "react-router-dom"

const Error = () => {
  const currentLanguage = localStorage.getItem(`local`) || `ru`
  const history = useHistory()
  useEffect(() => {
    const isError = !localStorage.getItem(`error`)
    if (isError && history) {
      history.push(`/`)
    } else {
      localStorage.removeItem(`error`)
    }
  }, [])
  return (
    <div className="ErrorWrapper">
      <div className="MainBlock">
        <div className="ErrorMainBlock" />
        <div className="ErrorBlockTitle ">
          <div className="MainBlockTitleUpper">404</div>
          <div className="MainBlockTitleDescription">
            {currentLanguage === `en`
              ? `Page not found`
              : `Страница не найдена`}
          </div>
          <div
            className="MainBlockBackButton Button"
            onClick={() => {
              localStorage.removeItem(`error`)
              history.push(`/`)
            }}
          >
            {currentLanguage === `en` ? `To main page` : `На главную`}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Error
