import React, { useRef } from "react"

const FadeInSection = (props: any) => {
  const [isVisible, setVisible] = React.useState(false)
  const domRef = useRef<HTMLInputElement>(null)
  React.useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => setVisible(entry.isIntersecting))
    })
    if (!!domRef) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      observer.observe(domRef.current)
    }
  }, [])
  return (
    <div
      className={`fade-in-section ${isVisible ? "is-visible" : ""}`}
      ref={domRef}
    >
      {props.children}
    </div>
  )
}

export default FadeInSection
