import React, { useEffect, useState } from "react"
import { ReactComponent as FooterLogo } from "./footer_logo.svg"
import { ReactComponent as FooterMobileLogo } from "./footer_mobile_logo.svg"
import { ReactComponent as FacebookIcon } from "./facebook.svg"
import { ReactComponent as InstagramIcon } from "./instagram.svg"
import { ReactComponent as WhatsUpIcon } from "./whatsapp.svg"
import { ReactComponent as TelegaIcon } from "./telegram.svg"

import requestApi from "api/request"

type ContactsType = {
  address: {
    ru: string
    en: string
  }
  mail: string
  phoneOne: string
  phoneTwo: string
  links: {
    facebook: string
    instagram: string
    whatsup: string
    telegram: string
  }
}

const Footer = ({ currentLanguage }: { currentLanguage: string }) => {
  const initialContacts: ContactsType = {
    address: {
      ru: `Москва. Яковоапостольский переулок, 7с1`,
      en: `7 building 1, Yakovoapostolsky lane, Moscow`,
    },
    mail: `info@gduck.ru`,
    phoneOne: `8 800 511 14 34`,
    phoneTwo: `+7 (495) 138-34-44`,
    links: {
      facebook: ``,
      instagram: ``,
      whatsup: ``,
      telegram: ``,
    },
  }
  const [contactsData, setContacts] = useState<ContactsType>(initialContacts)
  useEffect(() => {
    requestApi({ url: `/contacts`, method: `GET`, withToken: false }).then(
      ({ data }: { data: ContactsType }) => {
        if (!!data && !!data.address && !!data.address.ru) {
          setContacts(data)
        }
      }
    )
  }, [])
  const isEn = currentLanguage === `en`
  return (
    <div>
      <div className="FooterWrapper">
        <div className="FooterLeft">
          <FooterLogo />
          <div className="FooterWatermark">© {new Date().getFullYear()}. Golden Duck</div>
        </div>
        <div className="FooterRight">
          <div className="FooterPhone">
            <a href={`tel:${contactsData.phoneOne}`}>{contactsData.phoneOne}</a>
          </div>
          <div className="FooterDescriptionItem">
            {isEn ? contactsData.address.en : contactsData.address.ru}
          </div>
          <div className="FooterDescriptionItem">
            <a
              href={`mailto:${contactsData.mail}`}
              target="_blank"
              rel="noreferrer"
            >
              {contactsData.mail}
            </a>
          </div>
          <div className="FooterDescriptionItem">
            <a href={`tel:${contactsData.phoneTwo}`}>{contactsData.phoneTwo}</a>
          </div>
          <div className="FlexRow FooterMediaWrapper">
            {/*<div className="FooterFacebookWrapper">*/}
            {/*  <a href={contactsData.links.facebook}>*/}
            {/*    <FacebookIcon />*/}
            {/*  </a>*/}
            {/*</div>*/}
            <div className="FooterInstagramWrapper">
              <a href={contactsData.links.instagram}>
                <InstagramIcon />
              </a>
            </div>
            <div className="FooterWhatsUpWrapper">
              <a href={contactsData.links.whatsup}>
                <WhatsUpIcon />
              </a>
            </div>
            {/*<div className="FooterTelegaWrapper">*/}
            {/*  <a href={contactsData.links.telegram}>*/}
            {/*    <TelegaIcon />*/}
            {/*  </a>*/}
            {/*</div>*/}
          </div>
        </div>
      </div>
      <div className="FooterMobileWrapper">
        <FooterMobileLogo />

        <div className="FooterMobilePhone">
          <a href={`tel:${contactsData.phoneOne}`}>{contactsData.phoneOne}</a>
        </div>

        <div className="FooterMobileDescriptionItem">
          {isEn ? contactsData.address.en : contactsData.address.ru}
        </div>
        <div className="FooterMobileDescriptionItem">
          <a
            href={`mailto:${contactsData.mail}`}
            target="_blank"
            rel="noreferrer"
          >
            {contactsData.mail}
          </a>
        </div>
        <div className="FooterMobileDescriptionItem">
          <a href={`tel:${contactsData.phoneTwo}`}>{contactsData.phoneTwo}</a>
        </div>
        <div className="FlexRow FooterMediaWrapper">
          {/*<div className="FooterFacebookWrapper">*/}
          {/*  <a href={contactsData.links.facebook}>*/}
          {/*    <FacebookIcon />*/}
          {/*  </a>*/}
          {/*</div>*/}
          <div className="FooterInstagramWrapper">
            <a href={contactsData.links.instagram}>
              <InstagramIcon />
            </a>
          </div>
          <div className="FooterWhatsUpWrapper">
            <a href={contactsData.links.whatsup}>
              <WhatsUpIcon />
            </a>
          </div>
          {/*<div className="FooterTelegaWrapper">*/}
          {/*  <a href={contactsData.links.telegram}>*/}
          {/*    <TelegaIcon />*/}
          {/*  </a>*/}
          {/*</div>*/}
        </div>
        <div className="FooterMobileWatermark">© 2021. Golden Duck</div>
      </div>
    </div>
  )
}

export default Footer
